import { createAction } from '@reduxjs/toolkit';
import axios from 'axios';

import createAsyncThunk from '@/redux/createAsyncThunk';
import type { FacebookVerb } from '@constants';
import { selectApplicationData } from '@core/redux/selectors/applicationState';
import {
  createFacebookFeed,
  createInstagramComment,
  createInstagramMessage,
  createTwitterDirectMessageEvent,
  createTwitterStatus,
  createWhatsAppMessage,
  deleteFacebookFeed,
  deleteInstagramComment,
  editFacebookFeed,
  facebookFeedLike,
  facebookFeedUnlike,
  fetchFacebookFeeds,
  fetchFacebookMessages,
  fetchInstagramComments,
  fetchInstagramMessages,
  fetchTwitterDirectMessageEvents,
  fetchTwitterStatuses,
  fetchURLPreview,
  fetchWhatsAppMessages,
  markAsRead,
  patchTwitterDirectMessageEvent,
  patchTwitterStatusFavorited,
  patchTwitterStatusRetweeted,
  patchTwitterStatusStatus,
  sendFacebookMessage,
  updateTicketAssignment,
} from '@desk/api/conversation';
import { fetchTicketMessages } from '@desk/api/ticketDetail';
import { assignTicket, fetchTicket } from '@desk/api/tickets';
import {
  convertTwitterStatusTicketToMergedTwitterStatus,
  parseTwitterDirectMessageEventAttachments,
} from '@desk/utils/twitterUtils';
import { toast } from '@feather/components/notification';
import getErrorMessage from '@utils/getErrorMessage';
import logException from '@utils/logException';
import toastBadRequestWarning from '@utils/toastBadRequestWarning';

import type { ConversationState } from '../reducers/conversation';
import { selectDeskProject } from '../selectors/desk';

type FetchConversationMessagesType = 'initial' | 'prev' | 'next';
type FacebookFeedResponsePayload = { feed: FacebookFeedType };
interface FacebookFeedSimpleActionRequestPayload {
  ticketId: Ticket['id'];
  feed: FacebookFeedType;
}
interface FetchSocialMessagesRequestPayload {
  types: FetchConversationMessagesType;
  ticketId: Ticket['id'];
  nextLimit?: number;
  prevLimit?: number;
  ts: number;
  initial?: boolean;
}

export const ConversationActions = {
  fetchConversation: createAsyncThunk<Ticket, FetchTicketPayload>(
    'desk/conversation/fetchConversation',
    async (payload, { dispatch, getState, rejectWithValue }) => {
      const { region } = selectApplicationData(getState());
      const { pid } = selectDeskProject(getState());

      try {
        const { data } = await fetchTicket(pid, region, { ticketId: payload.ticketId });

        dispatch(ConversationActions.conversationFetched(data));
        return data;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error || '');
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),
  conversationFetched: createAction<Ticket>('desk/conversation/conversationFetched'),

  fetchConversationMessages: createAsyncThunk<
    {
      types: FetchConversationMessagesType;
      messages: SendbirdChatPlatformAPI.AllMessageType[];
      initialOrNextFetchedTimestamp?: number;
    },
    {
      types: FetchConversationMessagesType;
      ticketId: Ticket['id'];
      messageTs: number;
      prevLimit: number;
      nextLimit: number;
    }
  >('desk/conversation/fetchConversationMessages', async (payload, { getState, rejectWithValue }) => {
    const { region } = selectApplicationData(getState());
    const { pid } = selectDeskProject(getState());

    try {
      const { data } = await fetchTicketMessages(pid, region, {
        ticketId: payload.ticketId,
        messageTs: payload.messageTs,
        prevLimit: payload.prevLimit,
        nextLimit: payload.nextLimit,
      });

      const currentMessages = getState().conversation.messages as SendbirdChatPlatformAPI.AllMessageType[];
      if (payload.types === 'prev') {
        return {
          types: payload.types,
          messages: data.messages.concat(currentMessages),
        };
      }
      if (payload.types === 'next') {
        return {
          types: payload.types,
          messages: data.messages.length > 0 ? currentMessages.concat(data.messages) : currentMessages,
          initialOrNextFetchedTimestamp: data.messages.length > 0 ? Date.now() : undefined,
        };
      }
      return {
        types: payload.types,
        messages: data.messages,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toastBadRequestWarning(error);
        return rejectWithValue(error || '');
      }

      logException(error);
      return rejectWithValue(error);
    }
  }),

  conversationTicketMessageUpdated: createAction<{
    messageId: number;
    updated: Partial<SendbirdChatMessage.AllMessage | SendbirdChatPlatformAPI.AllMessageType>;
  }>('desk/conversation/conversationTicketMessageUpdated'),

  updateConversationTicketAssignment: createAsyncThunk<Assignment, PatchTicketAssignmentRequestPayload>(
    'desk/conversation/updateConversationTicketAssignment',
    async (payload, { dispatch, getState, rejectWithValue }) => {
      const { region } = selectApplicationData(getState());
      const { pid } = selectDeskProject(getState());

      try {
        const { data } = await updateTicketAssignment(pid, region, payload);

        dispatch(ConversationActions.conversationAssignmentSet(data));
        return data;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error || '');
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),
  /**
   * Update ticket assignment via API
   */
  conversationAssignmentSet: createAction<Assignment | null>('desk/conversation/conversationAssignmentSet'),

  conversationReset: createAction('desk/conversation/conversationReset'),
  conversationMessagesReset: createAction('desk/conversation/conversationMessagesReset'),

  // URL Preview
  fetchURLPreview: createAsyncThunk<
    { url: string; siteName: string; title: string; description: string; image: string },
    { url: string }
  >('desk/conversation/fetchURLPreview', async (payload, { getState, rejectWithValue }) => {
    const { region } = selectApplicationData(getState());
    const { pid } = selectDeskProject(getState());

    try {
      const { data } = await fetchURLPreview({ pid, region, url: payload.url });

      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toastBadRequestWarning(error);
        return rejectWithValue(error || '');
      }

      logException(error);
      return rejectWithValue(error);
    }
  }),

  // assign ticket to myself
  assignTicketToMyself: createAsyncThunk<void, AssignTicketPayload>(
    'desk/conversation/assignTicketToMyself',
    async (payload, { dispatch, getState, rejectWithValue }) => {
      const { region } = selectApplicationData(getState());
      const { pid } = selectDeskProject(getState());

      try {
        await assignTicket(pid, region, payload);

        dispatch(ConversationActions.fetchConversation(payload));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error || '');
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),

  markAsRead: createAsyncThunk<{ ticket: Ticket }, { ticketId: Ticket['id'] }>(
    'desk/conversation/markAsRead',
    async (payload, { getState, rejectWithValue }) => {
      const { region } = selectApplicationData(getState());
      const { pid } = selectDeskProject(getState());

      try {
        const { data } = await markAsRead({ pid, region, ticketId: payload.ticketId });

        return { ticket: data };
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error || '');
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),

  typingStatusSet: createAction<ConversationState['typingStatus']>('desk/conversation/typingStatusSet'),
  agentTypingStatusSet: createAction<TypingStatus['agentTyping']>('desk/conversation/agentTypingStatusSet'),
  othersTypingStatusSet: createAction<Pick<TypingStatus, 'othersTyping' | 'typingMembers'>>(
    'desk/conversation/othersTypingStatusSet',
  ),

  /**
   * Social
   */

  // facebook - message
  fetchFacebookMessages: createAsyncThunk<
    {
      types: FetchConversationMessagesType;
      facebookMessages: FacebookPageMessage[];
      initialOrNextFetchedTimestamp?: number;
    },
    FetchSocialMessagesRequestPayload
  >('desk/conversation/fetchFacebookMessages', async (payload, { getState, rejectWithValue }) => {
    const { region } = selectApplicationData(getState());
    const { pid } = selectDeskProject(getState());
    const { ts, prevLimit = 0, nextLimit = 0 } = payload;
    const params = `prevLimit=${prevLimit}&nextLimit=${nextLimit}&ts=${ts}`;

    try {
      const { data } = await fetchFacebookMessages(pid, region, { ticketId: payload.ticketId, params });

      const currentMessages = getState().conversation.facebookMessages;
      if (payload.types === 'prev') {
        return {
          types: payload.types,
          facebookMessages: data.results.concat(currentMessages),
        };
      }
      return {
        types: payload.types,
        facebookMessages: data.results,
        initialOrNextFetchedTimestamp: Date.now(),
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toastBadRequestWarning(error);
        return rejectWithValue(error || '');
      }

      logException(error);
      return rejectWithValue(error);
    }
  }),

  sendFacebookMessage: createAsyncThunk<
    FacebookPageMessage,
    {
      ticketId: Ticket['id'];
      facebookPageId: FacebookPage['pageId'];
      payload: { recipientId: string; messageText?: string; filedata?: File | string };
    }
  >('desk/conversation/sendFacebookMessage', async (payload, { dispatch, getState, rejectWithValue }) => {
    const { region } = selectApplicationData(getState());
    const { pid } = selectDeskProject(getState());

    try {
      const { data } = await sendFacebookMessage({
        pid,
        region,
        ticketId: payload.ticketId,
        payload: payload.payload,
      });

      dispatch(
        ConversationActions.facebookMessageUpdated({
          facebookMessage: {
            ...data,
            senderId: payload.facebookPageId,
            timestamp: Date.now(),
            text: payload.payload.messageText,
            ticket: payload.ticketId,
            isEcho: true,
            isTemp: true,
          },
        }),
      );
      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toastBadRequestWarning(error);
        return rejectWithValue(error || '');
      }

      logException(error);
      return rejectWithValue(error);
    }
  }),
  facebookMessageUpdated: createAction<{ facebookMessage: FacebookPageMessage }>(
    'desk/conversation/facebookMessageUpdated',
  ),

  // facebook - feed
  fetchFacebookFeeds: createAsyncThunk<
    { types: FetchConversationMessagesType; facebookFeeds: FacebookFeedType[]; initialOrNextFetchedTimestamp?: number },
    {
      types: FetchConversationMessagesType;
      ticketId: Ticket['id'];
      nextLimit?: number;
      prevLimit?: number;
      ts: number;
      initial?: boolean;
    }
  >('desk/conversation/fetchFacebookFeeds', async (payload, { getState, rejectWithValue }) => {
    const { region } = selectApplicationData(getState());
    const { pid } = selectDeskProject(getState());

    const { ts, prevLimit = 0, nextLimit = 0 } = payload;
    const params = `prevLimit=${prevLimit}&nextLimit=${nextLimit}&ts=${ts}`;

    try {
      const { data } = await fetchFacebookFeeds(pid, region, { ticketId: payload.ticketId, params });

      const currentMessages = getState().conversation.facebookFeeds;
      if (payload.types === 'prev') {
        return {
          types: payload.types,
          facebookFeeds: data.results.concat(currentMessages),
        };
      }
      return {
        types: payload.types,
        facebookFeeds: data.results,
        initialOrNextFetchedTimestamp: Date.now(),
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toastBadRequestWarning(error);
        return rejectWithValue(error || '');
      }

      logException(error);
      return rejectWithValue(error);
    }
  }),

  createFacebookFeed: createAsyncThunk<
    void,
    {
      ticketId: Ticket['id'];
      fromId: string;
      payload: { parentFeedId: string; filedata?: File | string; messageText?: string };
    }
  >('desk/conversation/createFacebookFeed', async (payload, { dispatch, getState, rejectWithValue }) => {
    const { region } = selectApplicationData(getState());
    const { pid } = selectDeskProject(getState());

    try {
      const { data } = await createFacebookFeed({
        pid,
        region,
        ticketId: payload.ticketId,
        payload: payload.payload,
      });

      dispatch(
        ConversationActions.facebookFeedsUpdated({
          id: 0,
          attachments: '',
          feedId: data.feedId,
          fromId: payload.fromId,
          parentId: payload.payload.parentFeedId,
          feedType: 'comment',
          timestamp: new Date().valueOf(),
          message: payload.payload.messageText || '',
          // ticket: payload.ticketId,
          isTemp: true,
          reactions: '{"reactionCounts": {}, "pageReactions": []}',
          createdAt: '',
          status: 'default',
          messageType: 'social',
        }),
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.warning({ message: getErrorMessage(error) });
        return rejectWithValue(error || '');
      }

      logException(error);
      return rejectWithValue(error);
    }
  }),

  facebookFeedRequestUpdated: createAction<{ feed: FacebookFeedType; verb?: FacebookVerb }>(
    'desk/conversation/facebookFeedRequestUpdated',
  ),

  editFacebookFeed: createAsyncThunk<
    FacebookFeedResponsePayload,
    { ticketId: number; feedId: string; messageText?: string; filedata?: File | string; isHidden?: boolean }
  >('desk/conversation/editFacebookFeed', async (payload, { dispatch, getState, rejectWithValue }) => {
    const { region } = selectApplicationData(getState());
    const { pid } = selectDeskProject(getState());

    try {
      const { data } = await editFacebookFeed({ pid, region, payload });

      dispatch(ConversationActions.facebookFeedsUpdated(data));
      return { feed: data };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toastBadRequestWarning(error);
        return rejectWithValue(error || '');
      }

      logException(error);
      return rejectWithValue(error);
    }
  }),

  facebookFeedsUpdated: createAction<FacebookFeedType>('desk/conversation/facebookFeedsUpdated'),

  deleteFacebookFeed: createAsyncThunk<FacebookFeedResponsePayload, FacebookFeedSimpleActionRequestPayload>(
    'desk/conversation/deleteFacebookFeed',
    async (payload, { getState, rejectWithValue }) => {
      const { region } = selectApplicationData(getState());
      const { pid } = selectDeskProject(getState());

      try {
        const { data } = await deleteFacebookFeed({
          pid,
          region,
          ticketId: payload.ticketId,
          feedId: payload.feed.feedId,
        });

        return { feed: data };
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error || '');
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),

  facebookFeedLike: createAsyncThunk<FacebookFeedType, FacebookFeedSimpleActionRequestPayload>(
    'desk/conversation/facebookFeedLike',
    async (payload, { getState, rejectWithValue }) => {
      const { region } = selectApplicationData(getState());
      const { pid } = selectDeskProject(getState());

      try {
        const { data } = await facebookFeedLike({
          pid,
          region,
          ticketId: payload.ticketId,
          feedId: payload.feed.feedId,
        });

        return data;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error || '');
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),

  facebookFeedUnlike: createAsyncThunk<FacebookFeedType, FacebookFeedSimpleActionRequestPayload>(
    'desk/conversation/facebookFeedUnlike',
    async (payload, { getState, rejectWithValue }) => {
      const { region } = selectApplicationData(getState());
      const { pid } = selectDeskProject(getState());

      try {
        const { data } = await facebookFeedUnlike({
          pid,
          region,
          ticketId: payload.ticketId,
          feedId: payload.feed.feedId,
        });

        return data;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error || '');
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),

  // twitter
  fetchTwitterDirectMessages: createAsyncThunk<
    {
      types: FetchConversationMessagesType;
      messages: AttachmentParsedTwitterDirectMessageEvent[];
      initialOrNextFetchedTimestamp?: number;
    },
    FetchSocialMessagesRequestPayload
  >('desk/conversation/fetchTwitterDirectMessages', async (payload, { getState, rejectWithValue }) => {
    const { region } = selectApplicationData(getState());
    const { pid } = selectDeskProject(getState());
    const { ts, prevLimit = 0, nextLimit = 0 } = payload;
    const params = { prevLimit, nextLimit, ts };

    try {
      const { data } = await fetchTwitterDirectMessageEvents(pid, region, {
        ticketId: payload.ticketId,
        params,
      });

      const currentMessages = getState().conversation.twitterDirectMessages;
      const parsedMessages = data.results.map(parseTwitterDirectMessageEventAttachments);
      if (payload.types === 'prev') {
        return {
          types: payload.types,
          messages: parsedMessages.concat(currentMessages),
        };
      }
      return {
        types: payload.types,
        messages: parsedMessages,
        initialOrNextFetchedTimestamp: Date.now(),
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toastBadRequestWarning(error);
        return rejectWithValue(error);
      }

      logException(error);
      return rejectWithValue(error);
    }
  }),

  createTwitterDirectMessage: createAsyncThunk<
    AttachmentParsedTwitterDirectMessageEvent,
    { ticket: Ticket; recipientId: string; messageText: string; mediaId?: string }
  >('desk/conversation/createTwitterDirectMessage', async (payload, { getState, rejectWithValue }) => {
    const { region } = selectApplicationData(getState());
    const { pid } = selectDeskProject(getState());

    try {
      const { data } = await createTwitterDirectMessageEvent(pid, region, {
        ticketId: payload.ticket.id,
        recipientId: payload.recipientId,
        messageText: payload.messageText,
        mediaId: payload.mediaId,
      });

      return parseTwitterDirectMessageEventAttachments({ ...data, ticket: payload.ticket });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toastBadRequestWarning(
          (error.response?.data as { code: string })?.code === 'desk400104'
            ? window.intl.formatMessage({ id: 'desk.conversation.twitter.message.noti.tooLong' })
            : error,
        );
        return rejectWithValue(error);
      }

      logException(error);
      return rejectWithValue(error);
    }
  }),

  deleteTwitterDirectMessageEvent: createAsyncThunk<TwitterDirectMessageEvent, { id: TwitterDirectMessageEvent['id'] }>(
    'desk/conversation/deleteTwitterDirectMessageEvent',
    async (payload, { getState, rejectWithValue }) => {
      const { region } = selectApplicationData(getState());
      const { pid } = selectDeskProject(getState());

      try {
        const { data } = await patchTwitterDirectMessageEvent(pid, region, { id: payload.id, status: 'remove' });

        return data;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error);
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),

  twitterDirectMessageEventUpdated: createAction<AttachmentParsedTwitterDirectMessageEvent>(
    'desk/conversation/twitterDirectMessageEventUpdated',
  ),

  fetchTwitterStatuses: createAsyncThunk<
    { types: FetchConversationMessagesType; messages: MergedTwitterStatus[]; initialOrNextFetchedTimestamp?: number },
    FetchSocialMessagesRequestPayload
  >('desk/conversation/fetchTwitterStatuses', async (payload, { getState, rejectWithValue }) => {
    const { region } = selectApplicationData(getState());
    const { pid } = selectDeskProject(getState());
    const { ts, prevLimit = 0, nextLimit = 0 } = payload;
    const params = { prevLimit, nextLimit, ts };

    try {
      const { data } = await fetchTwitterStatuses(pid, region, { ticketId: payload.ticketId, params });

      const currentMessages = getState().conversation.twitterStatuses;
      const mergedTwitterStatuses = data.results.map(convertTwitterStatusTicketToMergedTwitterStatus);
      if (payload.types === 'prev') {
        return {
          types: payload.types,
          messages: mergedTwitterStatuses.concat(currentMessages),
        };
      }
      return {
        types: payload.types,
        messages: mergedTwitterStatuses,
        initialOrNextFetchedTimestamp: Date.now(),
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toastBadRequestWarning(error);
        return rejectWithValue(error);
      }

      logException(error);
      return rejectWithValue(error);
    }
  }),

  createTwitterStatus: createAsyncThunk<
    MergedTwitterStatus,
    { ticket: Ticket; recipientId: string; messageText: string; inReplyToStatusId?: string; mediaIds?: string[] }
  >('desk/conversation/createTwitterStatus', async (payload, { getState, rejectWithValue }) => {
    const { region } = selectApplicationData(getState());
    const { pid } = selectDeskProject(getState());

    try {
      const { data } = await createTwitterStatus(pid, region, {
        ticketId: payload.ticket.id,
        recipientId: payload.recipientId,
        messageText: payload.messageText,
        inReplyToStatusId: payload.inReplyToStatusId,
        mediaIds: payload.mediaIds && payload.mediaIds.length > 0 ? payload.mediaIds : undefined,
      });

      return convertTwitterStatusTicketToMergedTwitterStatus(data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toastBadRequestWarning(error);
        return rejectWithValue(error);
      }

      logException(error);
      return rejectWithValue(error);
    }
  }),

  patchTwitterStatus: createAsyncThunk<TwitterStatus, { id: TwitterStatus['id']; status: TwitterStatus['status'] }>(
    'desk/conversation/patchTwitterStatus',
    async (payload, { getState, rejectWithValue }) => {
      const { region } = selectApplicationData(getState());
      const { pid } = selectDeskProject(getState());

      try {
        const { data } = await patchTwitterStatusStatus(pid, region, { id: payload.id, status: payload.status });

        return data;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error);
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),

  patchTwitterStatusTwitterUser: createAsyncThunk<
    | { twitterStatusId: TwitterStatus['id']; retweeted: boolean; retweetCount?: number }
    | { twitterStatusId: TwitterStatus['id']; favorited: boolean; favoriteCount?: number },
    {
      id: TwitterStatusTwitterUser['id'];
      twitterStatusId: TwitterStatus['id'];
      update: Pick<TwitterStatusTwitterUser, 'retweeted'> | Pick<TwitterStatusTwitterUser, 'favorited'>;
    }
  >(
    'desk/conversation/patchTwitterStatusTwitterUser',
    async (payload, { getState, rejectWithValue }) => {
      const { region } = selectApplicationData(getState());
      const { pid } = selectDeskProject(getState());

      try {
        const twitterStatus = getState().conversation.twitterStatuses.find(
          (item) => item.id === payload.twitterStatusId,
        )!;

        if ('retweeted' in payload.update) {
          const { data } = await patchTwitterStatusRetweeted(pid, region, {
            id: payload.id,
            retweeted: payload.update.retweeted,
          });

          return {
            twitterStatusId: payload.twitterStatusId,
            retweeted: data.retweeted,
            retweetCount: twitterStatus.retweetCount + (data.retweeted ? 1 : -1),
          };
        }
        if ('favorited' in payload.update) {
          const { data } = await patchTwitterStatusFavorited(pid, region, {
            id: payload.id,
            favorited: payload.update.favorited,
          });

          return {
            twitterStatusId: payload.twitterStatusId,
            favorited: data.favorited,
            favoriteCount: twitterStatus.favoriteCount + (data.favorited ? 1 : -1),
          };
        }
        return rejectWithValue('');
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(getErrorMessage(error));
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
    {
      condition: (arg, { getState }) =>
        !!getState().conversation.twitterStatuses.find((item) => item.id === arg.twitterStatusId),
    },
  ),

  twitterStatusFromDeskEventUpdated: createAction<MergedTwitterStatus>(
    'desk/conversation/twitterStatusFromDeskEventUpdated',
  ),

  fetchInstagramComments: createAsyncThunk<
    {
      types: FetchConversationMessagesType;
      instagramComments: InstagramCommentTicket[];
      initialOrNextFetchedTimestamp?: number;
    },
    {
      types: FetchConversationMessagesType;
      ticketId: Ticket['id'];
      nextLimit?: number;
      prevLimit?: number;
      ts: number;
      initial?: boolean;
    }
  >('desk/conversation/fetchInstagramComments', async (payload, { getState, rejectWithValue }) => {
    const { region } = selectApplicationData(getState());
    const { pid } = selectDeskProject(getState());
    const { ts, prevLimit = 0, nextLimit = 0 } = payload;
    const params = { prevLimit, nextLimit, ts };

    try {
      const { data } = await fetchInstagramComments(pid, region, { ticketId: payload.ticketId, params });

      const currentMessages = getState().conversation.instagramComments;
      if (payload.types === 'prev') {
        return {
          types: payload.types,
          instagramComments: data.results.concat(currentMessages),
        };
      }
      return {
        types: payload.types,
        instagramComments: data.results,
        initialOrNextFetchedTimestamp: Date.now(),
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error({ message: getErrorMessage(error) });
        return rejectWithValue(error);
      }

      logException(error);
      return rejectWithValue(error);
    }
  }),

  createInstagramComment: createAsyncThunk<
    void,
    {
      igMediaId: InstagramMedia['igMediaId'];
      instagramUsername?: InstagramUser['username'];
      parentIgCommentId?: string;
      text: string;
      ticket: Ticket;
    }
  >('desk/conversation/createInstagramComment', async (payload, { dispatch, getState, rejectWithValue }) => {
    const { region } = selectApplicationData(getState());
    const { pid } = selectDeskProject(getState());

    try {
      const { data } = await createInstagramComment(pid, region, {
        ticketId: payload.ticket.id,
        text: payload.text,
        parentIgCommentId: payload.parentIgCommentId,
        igMediaId: payload.igMediaId,
      });

      const message = {
        ...data,
        instagramComment: {
          ...data.instagramComment,
          senderId: payload.instagramUsername || '',
          isTemp: true,
        },
      };
      dispatch(ConversationActions.instagramCommentUpdated(message));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toastBadRequestWarning(error);
        return rejectWithValue(error);
      }

      logException(error);
      return rejectWithValue(error);
    }
  }),

  deleteInstagramComment: createAsyncThunk<
    InstagramComment,
    { ticketId: Ticket['id']; instagramCommentId: InstagramComment['id'] }
  >('desk/conversation/deleteInstagramComment', async (payload, { getState, rejectWithValue }) => {
    const { region } = selectApplicationData(getState());
    const { pid } = selectDeskProject(getState());

    try {
      const { data } = await deleteInstagramComment(pid, region, {
        ticketId: payload.ticketId,
        instagramCommentId: payload.instagramCommentId,
      });

      return data;
    } catch (error) {
      logException(error);
      return rejectWithValue(error);
    }
  }),

  instagramCommentUpdated: createAction<InstagramCommentTicket>('desk/conversation/instagramCommentUpdated'),
  instagramCommentFromDeskEventUpdated: createAction<InstagramCommentTicket>(
    'desk/conversation/instagramCommentFromDeskEventUpdated',
  ),

  fetchInstagramMessages: createAsyncThunk<
    {
      types: FetchConversationMessagesType;
      instagramMessages: InstagramMessageTicket[];
      initialOrNextFetchedTimestamp?: number;
    },
    {
      types: FetchConversationMessagesType;
      ticketId: Ticket['id'];
      nextLimit?: number;
      prevLimit?: number;
      ts: number;
      initial?: boolean;
    }
  >('desk/conversation/fetchInstagramMessages', async (payload, { getState, rejectWithValue }) => {
    const { region } = selectApplicationData(getState());
    const { pid } = selectDeskProject(getState());
    const { ts, prevLimit = 0, nextLimit = 0 } = payload;
    const params = { prevLimit, nextLimit, ts };

    try {
      const { data } = await fetchInstagramMessages(pid, region, { ticketId: payload.ticketId, params });

      const currentMessages = getState().conversation.instagramMessages;
      if (payload.types === 'prev') {
        return {
          types: payload.types,
          instagramMessages: data.results.concat(currentMessages),
        };
      }
      return {
        types: payload.types,
        instagramMessages: data.results,
        initialOrNextFetchedTimestamp: Date.now(),
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toastBadRequestWarning(error);
        return rejectWithValue(error || '');
      }

      logException(error);
      return rejectWithValue(error);
    }
  }),

  createInstagramMessage: createAsyncThunk<void, { text: string; media?: File | string; ticket: Ticket }>(
    'desk/conversation/createInstagramMessage',
    async (payload, { dispatch, getState, rejectWithValue }) => {
      const { region } = selectApplicationData(getState());
      const { pid } = selectDeskProject(getState());

      try {
        const { data } = await createInstagramMessage(pid, region, {
          ticketId: payload.ticket.id,
          text: payload.text,
          media: payload.media,
        });

        dispatch(ConversationActions.instagramMessageUpdated(data));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error);
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
  ),

  instagramMessageUpdated: createAction<InstagramMessageTicket>('desk/conversation/instagramMessageUpdated'),
  instagramMessageFromDeskEventUpdated: createAction<InstagramMessageTicket>(
    'desk/conversation/instagramMessageFromDeskEventUpdated',
  ),
  instagramMessageUnsendUpdated: createAction<InstagramMessageTicket>(
    'desk/conversation/instagramMessageUnsendUpdated',
  ),

  fetchWhatsAppMessages: createAsyncThunk<
    {
      types: FetchConversationMessagesType;
      whatsAppMessages: WhatsAppMessageType[];
      initialOrNextFetchedTimestamp?: number;
    },
    {
      types: FetchConversationMessagesType;
      ticketId: Ticket['id'];
      nextLimit?: number;
      prevLimit?: number;
      ts: number;
    }
  >('desk/conversation/fetchWhatsAppMessages', async (payload, { getState, rejectWithValue }) => {
    const { region } = selectApplicationData(getState());
    const { pid } = selectDeskProject(getState());
    const { prevLimit = 0, nextLimit = 0, ts, types } = payload;

    try {
      const { data } = await fetchWhatsAppMessages(pid, region, {
        ticketId: payload.ticketId,
        params: { prevLimit, nextLimit, ts },
      });

      const currentMessages = getState().conversation.whatsAppMessages;
      if (types === 'prev') {
        return {
          types,
          whatsAppMessages: data.results.concat(currentMessages),
        };
      }
      return {
        types,
        whatsAppMessages: data.results,
        initialOrNextFetchedTimestamp: Date.now(),
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toastBadRequestWarning(error);
        return rejectWithValue(error);
      }

      logException(error);
      return rejectWithValue(error);
    }
  }),

  createWhatsAppMessage: createAsyncThunk<
    WhatsAppMessageType,
    { ticketId: Ticket['id']; toNumber: string; messageText?: string; filedata?: File | string }
  >('desk/conversation/createWhatsAppMessage', async (payload, { getState, rejectWithValue }) => {
    const { region } = selectApplicationData(getState());
    const { pid } = selectDeskProject(getState());

    try {
      const { data } = await createWhatsAppMessage(pid, region, {
        ticketId: payload.ticketId,
        toNumber: payload.toNumber,
        messageText: payload.messageText,
        filedata: payload.filedata,
      });

      return data;
    } catch (error) {
      logException(error);
      return rejectWithValue(error);
    }
  }),

  whatsAppMessageFromDeskEventUpdated: createAction<WhatsAppMessageType>(
    'desk/conversation/whatsAppMessageFromDeskEventUpdated',
  ),
};

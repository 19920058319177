import { snakeCaseKeys } from '@utils/ObjectKeyConverter';

import DeskApiAxios from './DeskApiAxios';

export const fetchTicketMessages: FetchTicketMessagesAPI = (pid, region, payload) => {
  const axios = new DeskApiAxios({ pid, region });
  return axios.get(`tickets/${payload.ticketId}/messages/`, {
    params: { ...snakeCaseKeys(payload) },
  });
};

import type { ReactNode } from 'react';
import { useContext, useMemo } from 'react';

import type { FormatXMLElementFn, Options, PrimitiveType } from 'intl-messageformat';
import { useIntl } from 'react-intl';

import { IntlMessagesLoadingContext } from '@/intl/connectedIntlProvider';
import type { calls, chat, common, core, desk, oldMessages, ui } from '@/intl/messages';

export type MessageKeys =
  | keyof typeof common
  | keyof typeof chat
  | keyof typeof core
  | keyof typeof desk
  | keyof typeof calls
  | keyof typeof ui
  | keyof typeof oldMessages;

type AppMessageDescriptor = { id: MessageKeys };

interface FormatMessage {
  (
    descriptor: AppMessageDescriptor,
    values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
    opts?: Options,
  ): string;
  <T>(
    descriptor: AppMessageDescriptor,
    values?: Record<string, PrimitiveType | T | FormatXMLElementFn<T, ReactNode>>,
    opts?: Options,
  ): ReactNode;
}

function useAppIntl() {
  const { isLoading: isLoadingMessages } = useContext(IntlMessagesLoadingContext);
  const intl = useIntl();

  return useMemo(() => {
    const formatMessage: FormatMessage = (descriptor, values, opts) =>
      isLoadingMessages ? '' : intl.formatMessage(descriptor, values, opts);

    return { ...intl, formatMessage };
  }, [intl, isLoadingMessages]);
}

export type AppIntlShape = ReturnType<typeof useAppIntl>;

export default useAppIntl;

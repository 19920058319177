export const onboardingStepPaths = {
  STEP_ONE_PATH: '1',
  STEP_TWO_PATH: '2',
  STEP_THREE_PATH: '3',
};

// Users will be redirected to here⬇ when they sign up at sendbird.com
// {origin}/application-id/ai-chatbots/onboarding/steps
export const V2_FIRST_ONBOARDING_STEP_URL = '/ai-chatbots/onboarding/v2/steps';
export const V2_FIRST_ONBOARDING_STEP_FULL_URL = `/ai-chatbots/onboarding/v2/steps/${onboardingStepPaths.STEP_ONE_PATH}`;

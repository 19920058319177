import mixpanel from 'mixpanel-browser';

import config from '@/config';

const MIX_PANEL_TOKEN = '25b9836d96be2a7652460eeb68a8a6f8';
// const MIX_PANEL_TOKEN_TEST = '5b526707ac5bc65158c3832344c0a003'; // lucas test token

// Is managed by Casey, Hunter
// Bundlesize increased to 143kb for this package.
const initializeMixpanel = () => {
  if (config.BUILD_MODE !== 'staging') {
    mixpanel.init(MIX_PANEL_TOKEN, {
      debug: true,
      track_pageview: 'full-url',
      persistence: 'localStorage',
    });
  }
};

export default initializeMixpanel;

import { Suspense, lazy } from 'react';

import { Navigate, Route, createRoutesFromElements } from 'react-router-dom';

import config from '@/config';
import { Outlet, createBrowserRouter } from '@/router';
import InvitationTokensRedirect from '@common/containers/InvitationTokensRedirect';
import RouteErrorBoundary from '@common/containers/RouteErrorBoundary';
import RootLayout from '@common/containers/layout/RootLayout';
import { SubscriptionProduct } from '@constants';
import { SpinnerFull } from '@feather/components/spinner/spinnerFull';

const { BUILD_MODE } = config;

const AuthRoute = lazy(() => import('./AuthRoute'));
const GuideToDesktop = lazy(() => import('@common/containers/GuideToDesktop'));
const Integrations = lazy(() => import('./integrations'));
const NavigationLayout = lazy(() => import('@common/containers/layout/navigationLayout'));
const Activate = lazy(() => import('@common/containers/Activate'));
const App = lazy(() => import('@core/containers/app'));
const Auth = lazy(() => import('@common/containers/auth'));
const AwsMarketplace = lazy(() => import('@common/containers/AwsMarketplace'));
const Home = lazy(() => import('@common/containers/home'));
const Onboarding = lazy(() => import('@common/containers/Onboarding'));
const AIChatbotOnboarding = lazy(() => import('@chat/aibots/containers/Onboarding'));
const AIChatbotOnboardingV2 = lazy(() => import('@chat/aibots/containers/OnboardingV2'));
const AIChatbotWidgetPreview = lazy(() => import('@chat/aibots/containers/WidgetPreview'));
const OrganizationStatusScreen = lazy(() => import('@common/containers/home/OrganizationStatusScreen'));
const PageNotFound = lazy(() => import('@common/containers/PageNotFound'));

const CreateOrganization = lazy(() => import('@common/containers/Onboarding/CreateOrganization'));
const CreateApplication = lazy(() => import('@common/containers/Onboarding/CreateApplication'));

const Account = lazy(() => import('@common/containers/account'));
const AccountProfile = lazy(() => import('@common/containers/account/profile'));
const AccountSecurity = lazy(() => import('@common/containers/account/security'));

/* Org settings */
const Settings = lazy(() => import('@common/containers/settings'));
const SignInWithSso = lazy(() => import('@common/containers/auth/signInWithSso'));
const GeneralSetting = lazy(() => import('@common/containers/settings/general'));
const ApplicationsSetting = lazy(() => import('@common/containers/settings/applications'));
const Billings = lazy(() => import('@common/containers/settings/billings'));
const SubscriptionPlanModal = lazy(() => import('@common/containers/FullScreenModals/SubscriptionPlanModal'));
const APIKey = lazy(() => import('@common/containers/settings/apikey'));
const FeatureFlags = lazy(() => import('@common/containers/settings/featureFlags'));
const Members = lazy(() => import('@common/containers/settings/members'));
const Roles = lazy(() => import('@common/containers/settings/roles'));
const RolesForm = lazy(() => import('@common/containers/settings/roles/RolesForm'));
const RolesDetail = lazy(() => import('@common/containers/settings/roles/rolesDetail'));
const RolesList = lazy(() => import('@common/containers/settings/roles/rolesList'));
const Security = lazy(() => import('@common/containers/settings/security'));
const Usage = lazy(() => import('@common/containers/settings/usage'));
const UsageDetail = lazy(() => import('@common/containers/settings/usage/UsageDetail'));
const UsageList = lazy(() => import('@common/containers/settings/usage/UsageList'));
const Support = lazy(() => import('@common/containers/support'));
const SettingsFallback = lazy(() => import('@common/containers/settings/SettingsFallback'));
const PhoneNumbers = lazy(() => import('@common/containers/settings/PhoneNumbers'));
const AdvancedModerationUsageDetail = lazy(
  () => import('@common/containers/settings/usage/AdvancedModerationUsageDetail'),
);

/** @knipignore */
export const routes = createRoutesFromElements(
  <Route path="/" element={<RootLayout />} errorElement={<RouteErrorBoundary />}>
    <Route path="page-not-found" element={<PageNotFound />} />
    <Route path="integrations/*" element={<Integrations />} />
    {/* Task key: INVITATION_TOKEN_BINDER_FOR_AMPLIFY */}
    {/* Globally search with the task key above to find related code */}
    {BUILD_MODE !== 'production' && <Route path="tokens" element={<InvitationTokensRedirect />} />}
    <Route path="auth/*" element={<Auth />} />
    <Route path="sso/*" element={<SignInWithSso />} /> {/* Auth0 redirects user to this route not /auth/sso */}
    <Route path="activate/*" element={<Activate />} />
    <Route path="aws-marketplace/*" element={<AwsMarketplace />} />
    <Route
      path="*"
      element={
        <Suspense fallback={<SpinnerFull />}>
          <AuthRoute>
            <Outlet />
          </AuthRoute>
        </Suspense>
      }
    >
      <Route path="ai-chatbots/onboarding/v2">
        <Route path="steps/*" element={<AIChatbotOnboardingV2 />} />
        <Route path="" element={<Navigate to="steps" replace={true} />} />
      </Route>

      <Route path=":appId/guide-to-desktop" element={<GuideToDesktop />} />
      {/* `ai-chatbots/onboarding` route doesn't render GNB and LNB. 
      Therefore this router sits outside of the `ai-chatbot/*` hierarchy. */}
      <Route path=":appId/ai-chatbots/preview/:botId" element={<AIChatbotWidgetPreview />} />
      <Route path=":appId/ai-chatbots/onboarding">
        <Route path="steps/*" element={<AIChatbotOnboarding />} />
        <Route path="" element={<Navigate to="steps" replace={true} />} />
      </Route>
      <Route path="onboarding/*" element={<Onboarding />}>
        <Route path="organization" element={<CreateOrganization />} />
        <Route path="application" element={<CreateApplication />} />
        <Route path="*" element={<Navigate to="organization" replace={true} />} />
      </Route>
      <Route path="*" element={<NavigationLayout />}>
        <Route index={true} element={<Home />} />
        <Route path="account/*" element={<Account />}>
          <Route path="profile" element={<AccountProfile />} />
          <Route path="security" element={<AccountSecurity />} />
          <Route path="*" element={<Navigate to="profile" replace={true} />} />
        </Route>
        <Route path="settings/*" element={<Settings />}>
          <Route path="general/*" element={<GeneralSetting />}>
            <Route path="plans/ai_bots" element={<SubscriptionPlanModal product={SubscriptionProduct.AiBots} />} />
            <Route path="plans/chat" element={<SubscriptionPlanModal product={SubscriptionProduct.Chat} />} />
            <Route
              path="plans/notifications"
              element={<SubscriptionPlanModal product={SubscriptionProduct.BusinessMessaging} />}
            />
            <Route path="plans/desk" element={<SubscriptionPlanModal product={SubscriptionProduct.Desk} />} />
          </Route>
          <Route path="usage/*" element={<Usage />}>
            <Route path="advanced_moderation" element={<AdvancedModerationUsageDetail />} />
            <Route path=":featureKey" element={<UsageDetail />} />
            <Route path="" element={<UsageList />} />
            <Route path="*" element={<Navigate to="" replace={true} />} />
          </Route>
          <Route path="applications/*" element={<ApplicationsSetting />} />
          <Route path="phone-numbers/*" element={<PhoneNumbers />} />
          <Route path="members/*" element={<Members />} />
          <Route path="roles/*" element={<Roles />}>
            <Route path="create" element={<RolesForm />} />
            <Route path=":roleId/edit" element={<RolesForm />} />
            <Route path=":roleId" element={<RolesDetail />} />
            <Route path="" element={<RolesList />} />
            <Route path="*" element={<Navigate to="" replace={true} />} />
          </Route>
          <Route path="billing/*" element={<Billings />} />
          <Route path="security/*" element={<Security />} />
          <Route path="apikey/*" element={<APIKey />} />
          <Route path="contact_us/*" element={<Support />} />
          <Route path="feature_flags" element={<FeatureFlags />} />
          <Route path="*" element={<SettingsFallback />} />
        </Route>
        <Route path="deactivated" element={<OrganizationStatusScreen />} />
        <Route path=":appId/*" element={<App />} />
      </Route>
    </Route>
  </Route>,
);

export const browserRouter = createBrowserRouter(routes);

/**
 * [Customer Request]
 * @description https://sendbird.slack.com/archives/C05A3EN7FTJ/p1730774503968449
 * Added code to separate APIs for Group Channel and Supergroup channel as requested by the customer.
 */
export const transformToSupergroupChannelUrl = (channelUrl: string) => {
  const isSupergroupChannel = window.location.pathname.includes('supergroup-channels');
  if (isSupergroupChannel) {
    return channelUrl.replace('group_channels', 'supergroup_channels');
  }

  return channelUrl;
};

import config from '@/config';
import { cssVariables } from '@feather';

// REGEX
// eslint-disable-next-line no-useless-escape
export const EMAIL_PARSE_REGEX =
  // eslint-disable-next-line no-useless-escape
  /(?:(?:[^<>()\[\]\\.,;:+\s@"]+(?:\.[^<>()\[\]\\.,;:+\s@"]+)*)|(?:".+"))@(?:(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(?:(?:[a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

const EMAIL_PARSE_REGEX_ALLOW_ALIAS =
  // eslint-disable-next-line no-useless-escape
  /(?:(?:[^<>()\[\]\\.,;:\s@"]+(?:\.[^<>()\[\]\\.,;:\s@"]+)*)|(?:".+"))@(?:(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(?:(?:[a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

export const EMAIL_REGEX = new RegExp(`^${EMAIL_PARSE_REGEX.source}$`);
export const EMAIL_REGEX_ALLOW_ALIAS = new RegExp(`^${EMAIL_PARSE_REGEX_ALLOW_ALIAS.source}$`);

export const URL_PARSE_REGEX =
  /(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?/gi;
export const URL_REGEX = new RegExp(`^${URL_PARSE_REGEX.source}$`);

// eslint-disable-next-line no-useless-escape
export const URI_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^([a-z0-9+.-]+):(?:\/\/(?:((?:[a-z0-9-._~!$&'()*+,;=:]|%[0-9A-F]{2})*)@)?((?:[a-z0-9-._~!$&'()*+,;=]|%[0-9A-F]{2})*)(?::(\d*))?(\/(?:[a-z0-9-._~!$&'()*+,;=:@\/]|%[0-9A-F]{2})*)?|(\/?(?:[a-z0-9-._~!$&'()*+,;=:@]|%[0-9A-F]{2})+(?:[a-z0-9-._~!$&'()*+,;=:@\/]|%[0-9A-F]{2})*)?)(?:\?((?:[a-z0-9-._~!$&'()*+,;=:\/?@]|%[0-9A-F]{2})*))?(?:#((?:[a-z0-9-._~!$&'()*+,;=:\/?@]|%[0-9A-F]{2})*))?$/i;

export const IS_INTEGER_REGEX = /^[-]?[0-9]+$/;

// eslint-disable-next-line no-useless-escape
export const SPECIAL_CHARACTERS_EXCEPT_DASH_AND_UNDERSCORE = /[ `!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/;

// URLs
export const CDN_ASSETS_PREFIX = 'https://static.dashboard.sendbird.com/assets';

// Constants
export const ISO_DATE_FORMAT_DATE_FNS = 'yyyy-MM-dd';
export const ISO_DATE_FORMAT_REGEX = /\d{4}-\d{2}-\d{2}/;

export const LIST_LIMIT = 20;
export const AGENT_GROUP_LIST_LIMIT = 20;
export const APPLICATION_LIST_LIMIT = 10;
export const DEFAULT_PAGE_SIZE_OPTIONS = [10, 20, 50, 100] as readonly PerPage[];

/** @knipignore */
export const DEFAULT_LOCALE = 'en';

export const EMPTY_TEXT = '—';

export const MASKING = '●●●●●●●●●●●●●●●';

export const BYTES_IN_MEGABYTE = 1000000;
export const BYTES_IN_KILOBYTE = 1000;

export const isProduction = config.BUILD_MODE === 'production';
export const currentDashboardAppVersion = config.DASHBOARD_APP_VERSION;

// Countries
export const COUNTRY_LIST = [
  // Top 10: USA, South Korea, India, Indonesia, Brazil, Canada, UK, Turkey, Phillippines, Vietnam
  'United States',
  'South Korea',
  'India',
  'Indonesia',
  'Brazil',
  'Canada',
  'United Kingdom',
  'Turkey',
  'Philippines',
  'Vietnam',
  //
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antigua',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'British Indian Ocean Territory',
  'British Virgin Islands',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burma Myanmar',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Cape Verde',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Cook Islands',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Falkland Islands',
  'Faroe Islands',
  'Federated States of Micronesia',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macau',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'Netherlands Antilles',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'North Korea',
  'Northern Mariana Islands',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Republic of the Congo',
  'Reunion',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Barthelemy',
  'Saint Helena',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkmenistan',
  'Turks and Caicos Islands',
  'Tuvalu',
  'US Virgin Islands',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City',
  'Venezuela',
  'Wallis and Futuna',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];

export const COUNTRIES_TO_REGION: Record<string, RegionKey> = {
  Afghanistan: 'ap-8',
  Albania: 'eu-1',
  Algeria: 'eu-1',
  'American Samoa': 'ap-9',
  Andorra: 'eu-1',
  Angola: 'ap-5',
  Anguilla: 'us-3',
  Antigua: 'us-3',
  Argentina: 'us-3',
  Armenia: 'eu-1',
  Aruba: 'us-3',
  Australia: 'ap-9',
  Austria: 'eu-1',
  Azerbaijan: 'eu-1',
  Bahamas: 'us-3',
  Bahrain: 'ap-8',
  Bangladesh: 'ap-8',
  Barbados: 'us-3',
  Belarus: 'eu-1',
  Belgium: 'eu-1',
  Belize: 'us-1',
  Benin: 'ap-5',
  Bermuda: 'us-3',
  Bhutan: 'ap-8',
  Bolivia: 'us-3',
  'Bosnia and Herzegovina': 'eu-1',
  Botswana: 'ap-5',
  Brazil: 'us-3',
  'British Indian Ocean Territory': 'ap-8',
  'British Virgin Islands': 'us-3',
  Brunei: 'ap-5',
  Bulgaria: 'eu-1',
  'Burkina Faso': 'ap-5',
  'Burma Myanmar': 'ap-5',
  Burundi: 'ap-5',
  Cambodia: 'ap-5',
  Cameroon: 'ap-5',
  Canada: 'ca-1',
  'Cape Verde': 'us-3',
  'Cayman Islands': 'us-3',
  'Central African Republic': 'ap-5',
  Chad: 'ap-5',
  Chile: 'us-3',
  China: 'ap-1',
  Colombia: 'us-3',
  Comoros: 'ap-5',
  'Cook Islands': 'ap-9',
  'Costa Rica': 'us-1',
  Croatia: 'eu-1',
  Cuba: 'us-3',
  Cyprus: 'eu-1',
  'Czech Republic': 'eu-1',
  Denmark: 'eu-1',
  Djibouti: 'ap-5',
  Dominica: 'us-3',
  'Dominican Republic': 'us-3',
  Ecuador: 'us-3',
  Egypt: 'eu-1',
  'El Salvador': 'us-1',
  'Equatorial Guinea': 'ap-5',
  Eritrea: 'ap-5',
  Estonia: 'eu-1',
  Ethiopia: 'ap-5',
  'Falkland Islands': 'us-3',
  'Faroe Islands': 'eu-1',
  'Federated States of Micronesia': 'ap-1',
  Fiji: 'ap-9',
  Finland: 'eu-1',
  France: 'eu-1',
  'French Guiana': 'us-3',
  'French Polynesia': 'ap-9',
  Gabon: 'ap-5',
  Gambia: 'ap-5',
  Georgia: 'eu-1',
  Germany: 'eu-1',
  Ghana: 'ap-5',
  Gibraltar: 'eu-1',
  Greece: 'eu-1',
  Greenland: 'us-3',
  Grenada: 'us-3',
  Guadeloupe: 'us-3',
  Guam: 'ap-1',
  Guatemala: 'us-1',
  Guinea: 'ap-5',
  'Guinea-Bissau': 'ap-5',
  Guyana: 'us-3',
  Haiti: 'us-3',
  Honduras: 'us-1',
  'Hong Kong': 'ap-1',
  Hungary: 'eu-1',
  Iceland: 'eu-1',
  India: 'ap-8',
  Indonesia: 'ap-5',
  Iran: 'ap-8',
  Iraq: 'ap-8',
  Ireland: 'eu-1',
  Israel: 'eu-1',
  Italy: 'eu-1',
  Jamaica: 'us-3',
  Japan: 'ap-1',
  Jordan: 'ap-8',
  Kazakhstan: 'ap-8',
  Kenya: 'ap-5',
  Kiribati: 'ap-9',
  Kuwait: 'ap-8',
  Kyrgyzstan: 'ap-8',
  Laos: 'ap-5',
  Latvia: 'eu-1',
  Lebanon: 'ap-8',
  Lesotho: 'ap-5',
  Liberia: 'ap-5',
  Libya: 'eu-1',
  Liechtenstein: 'eu-1',
  Lithuania: 'eu-1',
  Luxembourg: 'eu-1',
  Macau: 'ap-1',
  Macedonia: 'eu-1',
  Madagascar: 'ap-5',
  Malawi: 'ap-5',
  Malaysia: 'ap-5',
  Maldives: 'ap-8',
  Mali: 'ap-5',
  Malta: 'eu-1',
  'Marshall Islands': 'ap-1',
  Martinique: 'us-3',
  Mauritania: 'ap-5',
  Mauritius: 'ap-5',
  Mayotte: 'ap-5',
  Mexico: 'us-1',
  Moldova: 'eu-1',
  Monaco: 'eu-1',
  Mongolia: 'ap-1',
  Montenegro: 'eu-1',
  Montserrat: 'us-3',
  Morocco: 'eu-1',
  Mozambique: 'ap-5',
  Namibia: 'ap-5',
  Nauru: 'ap-9',
  Nepal: 'ap-8',
  Netherlands: 'eu-1',
  'Netherlands Antilles': 'us-3',
  'New Caledonia': 'ap-9',
  'New Zealand': 'ap-9',
  Nicaragua: 'us-1',
  Niger: 'ap-5',
  Nigeria: 'ap-5',
  Niue: 'ap-9',
  'Norfolk Island': 'ap-9',
  'North Korea': 'ap-2',
  'Northern Mariana Islands': 'ap-1',
  Norway: 'eu-1',
  Oman: 'ap-8',
  Pakistan: 'ap-8',
  Palau: 'ap-1',
  Palestine: 'ap-8',
  Panama: 'us-1',
  'Papua New Guinea': 'ap-9',
  Paraguay: 'us-3',
  Peru: 'us-3',
  Philippines: 'ap-5',
  Poland: 'eu-1',
  Portugal: 'eu-1',
  'Puerto Rico': 'us-3',
  Qatar: 'ap-8',
  'Republic of the Congo': 'ap-5',
  Reunion: 'ap-5',
  Romania: 'eu-1',
  Russia: 'eu-1',
  Rwanda: 'ap-5',
  'Saint Barthelemy': 'us-3',
  'Saint Helena': 'ap-5',
  'Saint Kitts and Nevis': 'us-3',
  'Saint Lucia': 'us-3',
  'Saint Martin': 'us-3',
  'Saint Pierre and Miquelon': 'ca-1',
  'Saint Vincent and the Grenadines': 'us-3',
  Samoa: 'ap-9',
  'San Marino': 'eu-1',
  'Sao Tome and Principe': 'ap-5',
  'Saudi Arabia': 'ap-8',
  Senegal: 'ap-5',
  Serbia: 'eu-1',
  Seychelles: 'ap-5',
  'Sierra Leone': 'ap-5',
  Singapore: 'ap-5',
  Slovakia: 'eu-1',
  Slovenia: 'eu-1',
  'Solomon Islands': 'ap-9',
  Somalia: 'ap-5',
  'South Africa': 'ap-5',
  'South Korea': 'ap-2',
  Spain: 'eu-1',
  'Sri Lanka': 'ap-8',
  Sudan: 'ap-5',
  Suriname: 'us-3',
  Swaziland: 'ap-5',
  Sweden: 'eu-1',
  Switzerland: 'eu-1',
  Syria: 'ap-8',
  Taiwan: 'ap-1',
  Tajikistan: 'ap-8',
  Tanzania: 'ap-5',
  Thailand: 'ap-5',
  'Timor-Leste': 'ap-5',
  Togo: 'ap-5',
  Tokelau: 'ap-9',
  Tonga: 'ap-9',
  'Trinidad and Tobago': 'us-3',
  Tunisia: 'eu-1',
  Turkey: 'eu-1',
  Turkmenistan: 'ap-8',
  'Turks and Caicos Islands': 'us-3',
  Tuvalu: 'ap-9',
  'US Virgin Islands': 'us-3',
  Uganda: 'ap-5',
  Ukraine: 'eu-1',
  'United Arab Emirates': 'ap-8',
  'United Kingdom': 'eu-1',
  'United States': 'us-3',
  Uruguay: 'us-3',
  Uzbekistan: 'ap-8',
  Vanuatu: 'ap-9',
  'Vatican City': 'eu-1',
  Venezuela: 'us-3',
  Vietnam: 'ap-5',
  'Wallis and Futuna': 'ap-9',
  Yemen: 'ap-8',
  Zambia: 'ap-5',
  Zimbabwe: 'ap-5',
};

// menus
export enum Product {
  aiChatbot = 'ai-chatbots',

  chat = 'chat',
  calls = 'calls',
  desk = 'desk',
  live = 'live',
  moderation = 'moderation',
  businessMessaging = 'Business Messaging',
}

export enum Page {
  // common
  overview = 'overview',
  users = 'users',
  settings = 'settings',

  // moderation
  allChannels = 'all_channels',
  openChannels = 'open_channels',
  groupChannels = 'group_channels_group',
  supergroupChannels = 'group_channels_supergroup',
  advancedModeration = 'advanced_moderation',
  moderationRules = 'moderation_rules',
  moderationLogs = 'moderation_log',
  moderationAllTicket = 'moderation_all_ticket',
  moderationMyTicket = 'moderation_my_ticket',
  flaggedUsers = 'flagged_users',
  moderatedUsers = 'moderated_users',
  moderationSettings = 'moderation_settings',
  moderationEnablementVisible = 'moderation_enablement_visible',
  moderatorMessage = 'moderator_message',
  moderationAnalytics = 'moderation_analytics',

  // chat
  announcements = 'announcements',
  messageSearch = 'messageSearch',
  messages = 'messages',
  dataExports = 'data_export',
  analytics = 'analytics',
  aiBots = 'ai_bots',
  aiBotsOnboarding = 'ai_bots_onboarding',
  aiBotManage = 'ai_bot_manage',
  aiBotList = 'ai_bot_list',
  aiBotWorkflow = 'ai_bot_workflow',
  aiBotKnowledgeManagement = 'ai_bot_knowledge_management',
  aiBotCustomActions = 'ai_bot_custom_actions',
  aiBotAnalyze = 'ai_bot_analyze',
  aiBotLogs = 'ai_bot_logs',
  aiAnalytics = 'ai_analytics',
  aiBotForm = 'ai_bot_form',
  aiBotIntegration = 'ai_bot_integration',
  aiSettings = 'ai_settings',
  uiKit = 'ui_kit',
  uiKitOnboarding = 'ui_kit_onboarding',
  chatSettings = 'chat_settings',

  // desk
  deskActivate = 'desk_activate',
  tickets = 'tickets',
  allTickets = 'all_tickets',
  conversation = 'conversation',
  views = 'views',
  assignmentLogs = 'assignment_logs',
  proactiveChat = 'proactive_chat',
  monitor = 'monitor',
  agents = 'agents',
  customers = 'customers',
  deskDataExport = 'desk_data_export',
  reports = 'reports',
  deskSettings = 'desk_settings',

  // calls
  directCalls = 'direct_calls',
  directCallsReports = 'direct_calls_reports',
  directCallLog = 'direct_call_log',
  directCallDataExports = 'direct_call_data_exports',
  groupCalls = 'group_calls',
  callsStudio = 'calls_studio',
  callsActivation = 'calls_activation',
  callsSettings = 'calls_settings',

  // live
  liveActivation = 'live_activation',
  liveEvents = 'live_events',
  liveStudio = 'live_studio',
  liveSettings = 'live_settings',

  // notifications
  notificationsEnablement = 'notifications_enablement',
  notificationsOnboarding = 'notifications_onboarding',
  notificationsCampaigns = 'notifications_campaigns',
  notificationsTemplates = 'notifications_templates',
  notificationsChannels = 'notifications_channels',
  notificationsUserInsights = 'notifications_user_insights',
  notificationsAnalytics = 'notifications_analytics',
  notificationsSettings = 'notification_settings',

  /** @knipignore */
  support = 'support',

  // higher level page
  organization = 'organization',
  application = 'application',
  desk = 'desk',
  calls = 'calls',
  notifications = 'notifications',
}

export enum DeskSettingsPage {
  general = 'general',
  aiFeatures = 'aiFeatures',
  automation = 'automation',
  triggers = 'triggers',
  bots = 'bots',
  systemMessages = 'systemMessages',
  assignmentRules = 'assignmentRules',
  priorityRules = 'priorityRules',
  quickReplies = 'quickReplies',
  tags = 'tags',
  teams = 'teams',
  customerFields = 'customerFields',
  ticketFields = 'ticketFields',
  security = 'security',
  credentials = 'credentials',
  webhooks = 'webhooks',
  integrations = 'integrations',
}

export enum AgentPage {
  tickets = 'tickets',
  assignmentLogs = 'assignmentLogs',
  proactiveChat = 'proactiveChat',
  customers = 'customers',
  settings = 'settings',
}

export enum OrganizationSettingMenu {
  general = 'general',
  usage = 'usage',
  applications = 'applications',
  phoneNumbers = 'phone-numbers',
  members = 'members',
  billing = 'billing',
  billing_invoices = 'billing_invoices',
  security = 'security',
  apikey = 'apikey',
  roles = 'roles',
  contactUs = 'contact_us',
  community = 'community',
  feature_flags = 'feature_flags',
}

export const PredefinedRoles = {
  OWNER: 'OWNER',
  ADMIN: 'ADMIN',
  BILLING: 'BILLING',
  DESK_ADMIN: 'DESK_ADMIN',
  DESK_AGENT: 'DESK_AGENT',
  CALL_USER: 'CALL_USER',
  DEFAULT: 'DEFAULT',
};

export const SuperRoles = [PredefinedRoles.OWNER, PredefinedRoles.ADMIN];

export const CONNECTION_COLORS = {
  ONLINE: cssVariables('green-5'),
  AWAY: cssVariables('orange-6'),
  OFFLINE: cssVariables('neutral-5'),
};

export const BYTES_PER_GIGABYTE = 1000 * 1000 * 1000;

export * from './common';
export * from './core';
export * from './calls';
export * from './desk';
export * from './organizations';

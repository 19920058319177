import { createContext, useCallback, useEffect, useMemo, useState } from 'react';

import { useAuth } from '@/redux/hooks/auth';
import type { AuthenticationState } from '@common/redux/reducers/authentication';
import type { AllPremiumFeatures } from '@constants';
import { DeskSettingsPage, LegacyPremiumFeatureMap, Page } from '@constants';
import useApplication from '@hooks/useApplication';
import useLatestValue from '@hooks/useLatestValue';
import useOrganization from '@hooks/useOrganization';

import useSelfServiceFeatures from './useSelfServiceFeatures';

type IsPermittedCombinedWithOption = 'and' | 'or';

/**
 * Returns a function checking if the user has given permissions
 * @param userPermissions user role's permission list
 * @knipignore
 */
export const isPermittedFactory =
  (userPermissions: PermissionKey[]) =>
  (
    allowedPermissions: readonly PermissionKey[],
    options: { combinedWith: IsPermittedCombinedWithOption } = { combinedWith: 'or' },
  ) => {
    switch (options.combinedWith) {
      case 'or':
        return userPermissions.some((permission) => allowedPermissions.includes(permission));
      case 'and':
        return allowedPermissions.every((permission) => userPermissions.includes(permission));
      default:
        throw new Error('combinedWith option must be either "and" or "or".');
    }
  };

/** @knipignore */
export const isAccessiblePageFactory = (userPermissions: PermissionKey[]) => (page: Page | DeskSettingsPage) => {
  switch (page) {
    // application
    case Page.application:
      return userPermissions.some(
        (v) =>
          v.startsWith('application.') ||
          v.startsWith('chat.') ||
          v.startsWith('moderation.openChannel.') ||
          v.startsWith('moderation.groupChannel.') ||
          v.startsWith('moderation.supergroupChannel.'),
      );
    // overview
    case Page.overview:
      return userPermissions.some((v) => v.startsWith('application.overview.'));

    // users
    case Page.users:
      return userPermissions.some((v) => v.startsWith('application.users.'));

    // notification
    case Page.notifications:
      return userPermissions.some((v) => v.startsWith('notifications.'));

    // chat
    case Page.dataExports:
      return userPermissions.some((v) => v.startsWith('chat.dataExport.'));
    case Page.aiBots:
      // FIXME(DFE-1152): remove chat.bots.ai after migrate all permissions
      return userPermissions.some((v) => v.startsWith('chat.bots.ai.') || v.startsWith('aiChatbot.'));
    case Page.uiKit:
      return userPermissions.some((v) => v.startsWith('chat.uikit.configuration.'));

    // moderation
    case Page.allChannels:
      return userPermissions.some(
        (v) =>
          v.startsWith('moderation.openChannel.') ||
          v.startsWith('moderation.groupChannel.') ||
          v.startsWith('moderation.supergroupChannel.'),
      );
    case Page.openChannels:
      return userPermissions.some((v) => v.startsWith('moderation.openChannel.'));
    case Page.groupChannels:
      return userPermissions.some((v) => v.startsWith('moderation.groupChannel.'));
    case Page.supergroupChannels:
      return userPermissions.some((v) => v.startsWith('moderation.supergroupChannel.'));
    case Page.advancedModeration:
      return (
        userPermissions.some((v) => v.startsWith('moderation.logs.')) ||
        userPermissions.some((v) => v.startsWith('moderation.rules.')) ||
        userPermissions.some((v) => v.startsWith('moderation.flaggedUsers.')) ||
        userPermissions.some((v) => v.startsWith('moderation.moderatedUsers.')) ||
        userPermissions.some((v) => v.startsWith('moderation.moderatorMessage.'))
      );
    case Page.moderationLogs:
      return userPermissions.some((v) => v.startsWith('moderation.logs.'));
    case Page.moderationRules:
      return userPermissions.some((v) => v.startsWith('moderation.rules.'));
    case Page.moderationAllTicket:
      return userPermissions.some((v) => v.startsWith('moderation.allTickets.'));
    case Page.moderationMyTicket:
      // FIXME(MOD-1408): This permission is temporary
      return userPermissions.some((v) => v.startsWith('moderation.myTickets.'));
    case Page.flaggedUsers:
      return userPermissions.some((v) => v.startsWith('moderation.flaggedUsers.'));
    case Page.moderatedUsers:
      return userPermissions.some((v) => v.startsWith('moderation.moderatedUsers.'));
    case Page.moderatorMessage:
      return userPermissions.some((v) => v.startsWith('moderation.moderatorMessage.'));
    case Page.moderationAnalytics:
      return userPermissions.some((v) => v.startsWith('moderation.analytics.'));
    // calls
    case Page.callsStudio:
      return userPermissions.some((v) => v.startsWith('calls.studio.'));
    case Page.directCalls:
      return userPermissions.some((v) => v.startsWith('calls.direct.'));
    case Page.groupCalls:
      return userPermissions.some((v) => v.startsWith('calls.group.'));

    // live
    case Page.liveStudio:
    case Page.liveEvents:
      return userPermissions.some((v) => v.startsWith('calls.'));

    // desk
    case Page.allTickets:
      return userPermissions.some((v) => v.startsWith('desk.tickets.all.'));
    case Page.conversation:
      return userPermissions.some((v) => v.startsWith('desk.tickets.yours.'));
    case Page.views:
      return userPermissions.some((v) => v.startsWith('desk.views.'));
    case Page.assignmentLogs:
      return userPermissions.some((v) => v.startsWith('desk.assignmentLogs.'));
    case Page.proactiveChat:
      return userPermissions.some((v) => v.startsWith('desk.proactiveChats.'));
    case Page.customers:
      return userPermissions.some((v) => v.startsWith('desk.customers.'));
    case Page.deskDataExport:
      return userPermissions.some((v) => v.startsWith('desk.dataExports.'));
    case Page.monitor:
      return userPermissions.some((v) => v.startsWith('desk.monitoring.'));
    case Page.reports:
      return userPermissions.some((v) => v.startsWith('desk.reports.'));
    case Page.deskSettings:
      return userPermissions.some((v) => v.startsWith('desk.settings.'));

    // settings :: desk
    case DeskSettingsPage.general:
      return userPermissions.some((v) => v.startsWith('desk.settings.general.'));
    // (DFE-904): This permission is temporary and valid before GA
    case DeskSettingsPage.aiFeatures:
      return userPermissions.some((v) => v.startsWith('desk.admin'));
    case DeskSettingsPage.automation:
      return userPermissions.some((v) => v.startsWith('desk.settings.automation.'));
    case DeskSettingsPage.triggers:
      return userPermissions.some((v) => v.startsWith('desk.settings.triggers.'));
    case DeskSettingsPage.bots:
      return userPermissions.some((v) => v.startsWith('desk.settings.bots.'));
    case DeskSettingsPage.systemMessages:
      return userPermissions.some((v) => v.startsWith('desk.settings.systemMessages.'));
    case DeskSettingsPage.assignmentRules:
      return userPermissions.some((v) => v.startsWith('desk.settings.rules.assignment.'));
    case DeskSettingsPage.priorityRules:
      return userPermissions.some((v) => v.startsWith('desk.settings.rules.priority.'));
    case DeskSettingsPage.quickReplies:
      return userPermissions.some((v) => v.startsWith('desk.settings.quickReplies.'));
    case DeskSettingsPage.tags:
      return userPermissions.some((v) => v.startsWith('desk.settings.tags.'));
    case DeskSettingsPage.teams:
      return userPermissions.some((v) => v.startsWith('desk.settings.teams.'));
    case DeskSettingsPage.customerFields:
      return userPermissions.some((v) => v.startsWith('desk.settings.customerFields.'));
    case DeskSettingsPage.ticketFields:
      return userPermissions.some((v) => v.startsWith('desk.settings.ticketFields.'));
    case DeskSettingsPage.security:
      return userPermissions.some((v) => v.startsWith('desk.settings.security.'));
    case DeskSettingsPage.credentials:
      return userPermissions.some((v) => v.startsWith('desk.settings.credentials.'));
    case DeskSettingsPage.webhooks:
      return userPermissions.some((v) => v.startsWith('desk.settings.webhooks.'));
    case DeskSettingsPage.integrations:
      return userPermissions.some((v) => v.startsWith('desk.settings.integrations.'));
    default:
      return userPermissions.some((permission) => permission.split('.').includes(page));
  }
};

type AuthorizationContext = {
  isFeatureEnabled: (feature: AllPremiumFeatures) => boolean;
  preparingFeatures: boolean;
  /**
   * Returns true if the authenticated user has any of the permissions passed as argument. You can optionally pass an
   * options argument which contains `combinedWith` property, which can be either `and` or `or` (default is `or`). If
   * it's `and`, it returns true only if the user has all the permissions passed. If it's `or`, it returns true if the
   * user has any of the permissions passed.
   */
  isPermitted: ReturnType<typeof isPermittedFactory>;
  isAccessiblePage: ReturnType<typeof isAccessiblePageFactory>;
  isSelfService: boolean;
} & Pick<AuthenticationState, 'user' | 'role' | 'authenticated'>;

export const AuthorizationContext = createContext<AuthorizationContext>({
  isPermitted: () => false,
  isAccessiblePage: () => false,
  isFeatureEnabled: () => false,
  preparingFeatures: true,
  isSelfService: true,
  user: {
    country_code: '',
    email: '',
    new_email: '',
    email_verified: false,
    first_name: '',
    last_name: '',
    country_name: '',
    nickname: '',
    profile_id: 0,
    profile_url: '',
    user_id: 0,
    two_factor_authentication: false,
    verification_email_sent: false,
    coachmark_completed: false,
    join_date: '',
    account_locked: false,
    last_password_changed_at: '',
    is_onboarding_survey_done: false,
  },
  role: {
    name: 'GUEST',
    permissions: [],
    is_predefined: false,
    id: 0,
    created_at: '',
    updated_at: '',
    description: '',
    application_access_controls: [],
    application_access_controls_details: [],
    is_application_access_control: false,
  },
  authenticated: false,
});

const isSetEqual = (a: Set<string>, b: Set<string>) => {
  if (a.size !== b.size) {
    return false;
  }
  for (const aElement of a) {
    if (!b.has(aElement)) {
      return false;
    }
  }
  return true;
};

const getLegacyFeatureSet = (premiumFeatures: Application['current_premium_features']) =>
  new Set(
    Object.entries(premiumFeatures)
      .filter(([, value]) => value === true) // filter boolean current_premium_feature values that are true
      .map(([key]) => LegacyPremiumFeatureMap[key])
      .filter((value): value is string => Boolean(value)), // filter possible undefined values
  );

const useLegacyFeatures = () => {
  const application = useApplication();
  const [result, setResult] = useState<Set<string> | null>(null);
  const latestResult = useLatestValue(result);

  useEffect(() => {
    const legacyFeatures = application && getLegacyFeatureSet(application.current_premium_features);
    const currentResult = latestResult.current;
    if (currentResult === legacyFeatures) {
      return;
    }
    if (currentResult == null || legacyFeatures == null || !isSetEqual(currentResult, legacyFeatures)) {
      setResult(legacyFeatures);
      return;
    }
  }, [application, latestResult]);

  const isLoading = application == null || result == null;

  return useMemo(() => ({ isLoading, result: result ? Array.from(result) : [] }), [isLoading, result]);
};

const useFeatureEnabled = () => {
  const isSelfService = useOrganization((current) => !!current.is_self_serve);
  const legacyFeatures = useLegacyFeatures();
  const selfServiceFeatures = useSelfServiceFeatures();
  const preparingFeatures = legacyFeatures.isLoading || (isSelfService ? selfServiceFeatures.isLoading : false);

  const features = useMemo(() => {
    if (isSelfService) {
      return [...selfServiceFeatures.result, ...legacyFeatures.result];
    }
    return legacyFeatures.result;
  }, [isSelfService, legacyFeatures.result, selfServiceFeatures.result]);

  const isFeatureEnabled = useCallback((feature) => features.includes(feature), [features]);
  return {
    preparingFeatures,
    features,
    isFeatureEnabled,
  };
};

export const AuthorizationContextProvider = ({ children }) => {
  const isSelfService = useOrganization((current) => !!current.is_self_serve);
  const { authenticated, user, role } = useAuth((auth) => ({
    authenticated: auth.authenticated,
    user: auth.user,
    role: auth.role,
  }));
  const { isFeatureEnabled, preparingFeatures } = useFeatureEnabled();

  /**
   * @deprecated Message search funtionality is deprecated.
   */
  const availablePermissions: PermissionKey[] = role.permissions.filter((permission) => {
    if (permission.includes('chat.messageSearch') && !isFeatureEnabled('message_search')) {
      return false;
    }
    return true;
  });

  // used to compare arrays in useMemo dependencies
  const availablePermissionsStringified = availablePermissions.join(',');

  const isPermitted = useMemo(
    () => isPermittedFactory(availablePermissionsStringified.split(',') as PermissionKey[]),
    [availablePermissionsStringified],
  );

  const isAccessiblePage = useMemo(
    () => isAccessiblePageFactory(availablePermissionsStringified.split(',') as PermissionKey[]),
    [availablePermissionsStringified],
  );

  return (
    <AuthorizationContext.Provider
      value={{
        authenticated,
        role,
        user,
        isPermitted,
        isAccessiblePage,
        isFeatureEnabled,
        preparingFeatures,
        isSelfService,
      }}
    >
      {children}
    </AuthorizationContext.Provider>
  );
};

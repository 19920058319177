/**
 * @description
 * const QueryKeyPrefix = {
 *   OrgInvitation: 'OrgInvitation',
 * } as const
 *
 * *Naming Rule*
 * {Product = ['Org', 'Chat', 'Calls', 'Desk', 'Live', 'BusinessMessaging', 'Application', 'User']}{Resource = As you wish}
 *
 * - Value is Same as key
 */
const QueryKeyPrefix = {
  // Carbon
  CarbonAiAuth: 'CarbonAiAuth',
  // Shopify
  ShopifyVerifyAuth: 'ShopifyVerifyAuth',
  ShopifyFetchSignupInfo: 'ShopifyFetchSignupInfo',
  ShopifyFetchApplications: 'ShopifyFetchApplications',
  ShopifyFetchBots: 'ShopifyFetchBots',
  ShopifyFetchEmbeddings: 'ShopifyFetchEmbeddings',
  ShopifyFetchStatus: 'ShopifyFetchStatus',

  // Organization
  OrgInvitation: 'OrgInvitation',
  OrgAccessControlSecurity: 'OrgAccessControlSecurity',
  OrgConfirmEmailChange: 'OrgConfirmEmailChange',
  OrgFetchOrganizationInvitations: 'orgFetchOrganizationInvitations',
  OrgSubscriptionInfo: 'OrgSubscriptionInfo',
  OrgPermissions: 'OrgPermissions',
  OrgMemberRoles: 'OrgMemberRoles',
  OrgMemberRole: 'OrgMemberRole',
  OrgVoucherUsage: 'OrgVoucherUsage',
  OrgVoucherUsageByRange: 'OrgVoucherUsageByRange',
  OrgPhoneNumbers: 'OrgPhoneNumbers',
  OrgAvailablePhoneNumbers: 'OrgAvailablePhoneNumbers',
  OrgOmegaAttributes: 'OrgOmegaAttributes',

  // App
  Application: 'Application',
  ApplicationMonthlyUsageWithOrgUsages: 'ApplicationMonthlyUsageWithOrgUsages',
  AppFetchEnabledFeatures: 'AppFetchEnabledFeatures',
  AppUser: 'AppUser',
  AppUsers: 'AppUsers',

  // Core
  CoreApplicationAttributes: 'CoreApplicationAttributes',
  CoreWebhookSettings: 'CoreWebhookSettings',

  // Chat
  ChannelMessages: 'ChannelMessages',
  ChatMessage: 'ChatMessage',
  ChatApiTokens: 'ChatApiTokens',
  ChatDefaultChannelInvitationPreference: 'ChatDefaultChannelInvitationPreference',
  ChatChannelMetadata: 'ChatChannelMetadata',
  ChatChannelData: 'ChatChannelData',
  ChatGroupChannelMembers: 'ChatGroupChannelMembers',
  ChatAiBots: 'ChatAiBots',
  ChatAiBot: 'ChatAiBot',
  ChatAiBotConversationLogs: 'ChatAiBotConversationLogs',
  ChatOpenAiEmbeddings: 'ChatOpenAiEmbeddings',
  ChatAiFunctionCall: 'ChatAiFunctionCall',
  ChatAiFunctionCalls: 'ChatAiFunctionCalls',
  ChatAiWorkflows: 'ChatAiWorkflows',
  ChatAiEmbeddingStatus: 'ChatAiEmbeddingStatus',
  ChatAiAnalytics: 'ChatAiAnalytics',
  ChatAiAnalyticsPopularItems: 'ChatAiAnalyticsPopularItems',
  ChatAiPromptHistory: 'ChatAiPromptHistory',
  ChatAiKnowledgeIndex: 'ChatAiKnowledgeIndex',
  ChatAiForm: 'ChatAiForm',
  ChatAiFormData: 'ChatAiFormData',
  ChatAiFormDataExports: 'ChatAiFormDataExports',
  ChatAiCrawledSiteDetail: 'ChatAiCrawledSiteDetail',
  ChatOpenChannel: 'ChatOpenChannel',
  ChatOpenChannelParticipants: 'ChatOpenChannelParticipants',
  ChatExtensions: 'ChatExtensions',
  ChatUserMessageHistoryUsers: 'ChatUserMessageHistoryUsers',
  ChatPoll: 'ChatPoll',
  ChatPin: 'ChatPin',
  ChatMetrics: 'ChatMetrics',
  ChannelManager: 'ChannelManager',
  ChannelList: 'ChannelList',

  // Moderation
  ModerationRule: 'ModerationRule',
  ModerationRules: 'ModerationRules',
  ModerationRuleSchema: 'ModerationRuleSchema',
  ModerationReviewTasksSupervision: 'ModerationReviewTasksSupervision',
  ModerationLog: 'ModerationLog',
  ModerationLogQuery: 'ModerationLogQuery',
  ModerationLogChannel: 'ModerationLogChannel',
  ModerationLogModerator: 'ModerationLogModerator',
  ModerationLogSender: 'ModerationLogSender',
  ModerationLogUser: 'ModerationLogUser',
  ModerationFlaggedUsers: 'ModerationFlaggedUsers',
  ModerationFlaggedUserSearch: 'ModerationFlaggedUserSearch',
  ModerationReviewQueues: 'ModerationReviewQueues',
  ModerationReviewQueue: 'ModerationReviewQueue',
  ModerationReviewQueueModerators: 'ModerationReviewQueueModerators',
  ModerationReviewQueueModeratorsById: 'ModerationReviewQueueModeratorsById',
  ModerationReviewQueueConfig: 'ModerationReviewQueueConfig',
  ModerationReviewTask: 'ModerationReviewTask',
  ModerationReviewTasks: 'ModerationReviewTasks',
  ModerationReviewQueueSettings: 'ModerationReviewQueueSettings',
  ModerationPinLimitSettings: 'ModerationPinLimitSettings',
  ModerationAssignReviewTasks: 'ModerationAssignReviewTasks',
  ModerationHiveDescription: 'ModerationHiveDescription',
  ModerationHiveConfiguration: 'ModerationHiveConfiguration',
  ModerationEngineEnableStatus: 'ModerationEngineEnableStatus',
  ModerationProfanityKeyword: 'ModerationProfanityKeyword',
  ModerationProfanityDefaultKeywords: 'ModerationProfanityDefaultKeywords',
  ModerationPenaltyActions: 'ModerationPenaltyActions',
  ModerationSDKUser: 'ModerationSDKUser',
  ModerationProfanityCustomType: 'ModerationProfanityCustomType',
  ModerationProfanityCustomTypeDuplicated: 'ModerationProfanityCustomTypeDuplicated',
  ModerationModeratedUsers: 'ModerationModeratedUsers',
  ModerationReviewTickets: 'ModerationReviewTickets',
  ModerationReviewTicket: 'ModerationReviewTicket',
  ModerationModeratorAction: 'ModerationModeratorAction',
  ModerationMemberSeatCount: 'ModerationMemberSeatCount',
  ModerationModeratorMessageList: 'ModerationModeratorMessageList',
  ModerationModeratorGroup: 'ModerationModeratorGroup',
  ModerationChannelById: 'ModerationChannelById',
  ModerationAnalyticsRuleTriggerCount: 'ModerationAnalyticsRuleTriggerCount',
  ModerationAnalyticsActionCount: 'ModerationAnalyticsActionCount',
  ModerationEnabledRuleCount: 'ModerationEnabledRuleCount',
  ModerationReportCategory: 'ModerationReportCategory',
  ModerationReportCategoryVisible: 'ModerationReportCategoryVisible',
  ModerationReportCategoryUsage: 'ModerationReportCategoryUsage',

  //UIKit
  UIKitConfig: 'UIKitConfig',

  // Desk
  DeskWebhooks: 'DeskWebhooks',
  DeskFetchCustomer: 'DeskFetchCustomer',
  DeskFetchAgentTicketCounts: 'DeskFetchAgentTicketCounts',
  DeskFetchAssignedTickets: 'DeskFetchAssignedTickets',
  DeskFetchRecentTicketsByCustomer: 'DeskFetchRecentTicketsByCustomer',
  DeskFetchTicketsByCustomer: 'DeskFetchTicketsByCustomer',
  DeskAgent: 'DeskAgent',
  DeskFetchTwitterUser: 'DeskFetchTwitterUser',
  DeskFollowTwitterUser: 'DeskFollowTwitterUser',
  DeskUnfollowTwitterUser: 'DeskUnfollowTwitterUser',
  DeskGetHTMLKey: 'DeskGetHTMLKey',
  DeskFetchIFrameApps: 'DeskFetchIFrameApps',
  DeskTicketTags: 'DeskTicketTags',
  DeskAIContexts: 'DeskAIContexts',
  DeskAIFeatureBotLinkedToResponseAssistant: 'DeskAIFeatureBotLinkedToResponseAssistant',
  DeskAIChatbotListProxy: 'DeskAIChatbotListProxy',
  DeskAIChatbotProxy: 'DeskAIChatbotProxy',
  DeskAIFeatureSummarize: 'DeskAIFeatureSummarize',

  // Calls
  CallsSettings: 'CallsSettings',
  CallsDirectCallsInitialStatus: 'CallsDirectCallsInitialStatus',
  CallsDirectCall: 'CallsDirectCall',
  CallsDirectCalls: 'CallsDirectCalls',
  CallsStat: 'CallsStat',
  CallsRooms: 'CallsRooms',
  CallsUsage: 'CallsUsage',
  CallsVoucherUsageByApp: 'CallsVoucherUsageByApp',
  CallsVoucherUsageByOrg: 'CallsVoucherUsageByOrg',
  CallsVoucherUsageByType: 'CallsVoucherUsageByType',
  CallsVoucherUsageAllApps: 'CallsVoucherUsageAllApps',

  // Live
  LiveLiveEvents: 'LiveLiveEvents',
  LiveLiveEvent: 'LiveLiveEvent',
  LiveOpenChannel: 'LiveOpenChannel',
  LiveCustomItems: 'LiveCustomItems',
  LiveCreditUsage: 'LiveCreditUsage',
  LiveUsage: 'LiveUsage',
  LiveSettings: 'LiveSettings',

  // Business Messaging
  NotificationsTemplates: 'NotificationsTemplates',
  NotificationsTemplate: 'NotificationsTemplate',
  NotificationsChannels: 'NotificationsChannels',
  NotificationsChannel: 'NotificationsChannel',
  NotificationsNotifications: 'NotificationsNotifications',
  NotificationsNotificationTargets: 'NotificationsNotificationTargets',
  NotificationsPreviewMessages: 'NotificationsPreviewMessages',
  NotificationsMessages: 'NotificationsMessages',
  NotificationsSettings: 'NotificationsSettings',
  NotificationsAppAttributes: 'NotificationsAppAttributes',
  NotificationsWebhook: 'NotificationsWebhook',
  NotificationsSampleAppQRCode: 'NotificationsSampleAppQRCode',
  NotificationsChatExtension: 'NotificationsChatExtension',
  NotificationsTags: 'NotificationsTags',
  NotificationsMetrics: 'NotificationsMetrics',
  NotificationsTimeSeriesMetrics: 'NotificationsTimeSeriesMetrics',
  NotificationsTagCount: 'NotificationsTagCount',
  NotificationsUsage: 'NotificationsUsage',
  NotificationsTemplateCount: 'NotificationsTemplateCount',

  // Common
  DashboardAppRecentVersion: 'DashboardAppRecentVersion',
  UseInvoice: 'UseInvoice',

  // Settings
  // -- Push notification
  PushNotificationConfiguration: 'PushNotificationConfiguration',
  UseChannelMessage: 'UseChannelMessage',

  // Omega
  OmegaSMSChannels: 'OmegaSMSChannels',
  OmegaChannelBindings: 'OmegaChannelBindings',
  OmegaKakaoSenderCategories: 'OmegaKakaoSenderCategories',
  OmegaKakaoTemplateCategories: 'OmegaKakaoTemplateCategories',
} as const;

export default QueryKeyPrefix;

import * as amplitude from '@amplitude/analytics-browser';

import config from '@/config';

const AMPLITUDE_API_KEY = '1f5cf5263a02720c811210f12c1714ec';

// Is managed by Casey, Hunter
// Bundlesize increased to 170kb for this package.
const initializeAmplitude = () => {
  if (config.BUILD_MODE !== 'staging') {
    amplitude.init(AMPLITUDE_API_KEY);
  }
};

export default initializeAmplitude;

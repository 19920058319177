import { useAeroAvailabilityStore } from '@aero/stores/AeroAvailability';
import { SessionStorage } from '@utils';

export const verifyIsAiAgentOrganization = (organizations: readonly Organization[]) => {
  const aiAgentDashboardType = organizations?.[0]?.ai_agent_dashboard_type;
  if (aiAgentDashboardType === 'AI_AGENT_ONLY' || aiAgentDashboardType === 'AI_AGENT_AND_CHAT') {
    // This org can use Aero (AI Agent)
    useAeroAvailabilityStore.getState().setIsAeroAvailable(true);
  }

  if (aiAgentDashboardType === 'AI_AGENT_ONLY') {
    // This org is Agent first organization
    SessionStorage.set('isAeroMode', 'true');
    // force refresh for session storage access
    location.href = '/';
    return;
  }
};

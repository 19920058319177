import { createAction } from '@reduxjs/toolkit';
import axios from 'axios';

import createAsyncThunk from '@/redux/createAsyncThunk';
import { selectApplicationData } from '@core/redux/selectors/applicationState';
import {
  fetchFacebookFeeds,
  fetchFacebookMessages,
  fetchInstagramComments,
  fetchInstagramMessages,
  fetchTwitterDirectMessageEvents,
  fetchTwitterStatuses,
  fetchWhatsAppMessages,
} from '@desk/api/conversation';
import { fetchTicketMessages } from '@desk/api/ticketDetail';
import { getGeneralizedTicketChannelType } from '@desk/utils';
import {
  convertTwitterStatusTicketToMergedTwitterStatus,
  parseTwitterDirectMessageEventAttachments,
} from '@desk/utils/twitterUtils';
import logException from '@utils/logException';
import toastBadRequestWarning from '@utils/toastBadRequestWarning';

import { selectDeskProject } from '../selectors/desk';

type FetchTicketHistoryMessagesType = 'initial' | 'prev' | 'next';

export const TicketHistoryActions = {
  fetchTicketHistoryMessages: createAsyncThunk<
    { types: FetchTicketHistoryMessagesType; messages: PlatformAPITicketMessage[] },
    {
      channelType: Ticket['channelType'];
      types: FetchTicketHistoryMessagesType;
      ticketId: number;
      messageTs: number;
      prevLimit: number;
      nextLimit: number;
      channelUrl?: string;
    }
  >(
    'desk/ticketHistory/fetchTicketHistoryMessages',
    async (payload, { getState, rejectWithValue }) => {
      const { region } = selectApplicationData(getState());
      const { pid } = selectDeskProject(getState());
      const { ticketId, channelType, messageTs, prevLimit = 0, nextLimit = 0 } = payload;

      const getResultReturnValue = (messages: PlatformAPITicketMessage[]) => {
        const currentMessages = getState().ticketHistory.messages;
        if (payload.types === 'prev') {
          return {
            types: payload.types,
            messages: messages.concat(currentMessages),
          };
        }
        if (payload.types === 'next') {
          return {
            types: payload.types,
            messages: currentMessages.concat(messages),
          };
        }
        return {
          types: payload.types,
          messages,
        };
      };

      try {
        switch (channelType) {
          case 'FACEBOOK_CONVERSATION': {
            const params = `prevLimit=${prevLimit}&nextLimit=${nextLimit}&ts=${messageTs}`;
            const { data } = await fetchFacebookMessages(pid, region, { ticketId: payload.ticketId, params });

            const messages = data.results;
            return getResultReturnValue(messages);
          }
          case 'FACEBOOK_FEED': {
            const params = `prevLimit=${prevLimit}&nextLimit=${nextLimit}&ts=${messageTs}`;
            const { data } = await fetchFacebookFeeds(pid, region, { ticketId: payload.ticketId, params });

            const messages = data.results;
            return getResultReturnValue(messages);
          }
          case 'TWITTER_DIRECT_MESSAGE_EVENT': {
            const params = { prevLimit, nextLimit, ts: messageTs };
            const { data } = await fetchTwitterDirectMessageEvents(pid, region, { ticketId: payload.ticketId, params });

            const messages = data.results.map(parseTwitterDirectMessageEventAttachments);
            return getResultReturnValue(messages);
          }
          case 'TWITTER_STATUS': {
            const params = { prevLimit, nextLimit, ts: messageTs };
            const { data } = await fetchTwitterStatuses(pid, region, { ticketId: payload.ticketId, params });

            const messages = data.results.map(convertTwitterStatusTicketToMergedTwitterStatus);
            return getResultReturnValue(messages);
          }
          case 'INSTAGRAM_COMMENT': {
            const params = { prevLimit, nextLimit, ts: messageTs };
            const { data } = await fetchInstagramComments(pid, region, { ticketId: payload.ticketId, params });

            const messages = data.results;
            return getResultReturnValue(messages);
          }
          case 'INSTAGRAM_MESSAGE': {
            const params = { prevLimit, nextLimit, ts: messageTs };
            const { data } = await fetchInstagramMessages(pid, region, { ticketId: payload.ticketId, params });

            const messages = data.results;
            return getResultReturnValue(messages);
          }
          case 'WHATSAPP_MESSAGE': {
            const params = { prevLimit, nextLimit, ts: messageTs };
            const { data } = await fetchWhatsAppMessages(pid, region, { ticketId: payload.ticketId, params });

            const messages = data.results;
            return getResultReturnValue(messages);
          }
          case 'SENDBIRD_JAVASCRIPT':
          case 'SENDBIRD_IOS':
          case 'SENDBIRD_ANDROID':
          case 'SENDBIRD':
          default: {
            const { data } = await fetchTicketMessages(pid, region, {
              messageTs,
              prevLimit,
              nextLimit,
              ticketId,
            });
            const { messages } = data;
            return getResultReturnValue(messages);
          }
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toastBadRequestWarning(error);
          return rejectWithValue(error || '');
        }

        logException(error);
        return rejectWithValue(error);
      }
    },
    { condition: (arg) => getGeneralizedTicketChannelType(arg.channelType) !== 'sendbird' || !!arg.channelUrl },
  ),

  currentTicketHistorySet: createAction<Ticket>('desk/ticketHistory/currentTicketHistorySet'),
  currentTicketHistoryReset: createAction('desk/ticketHistory/currentTicketHistoryReset'),
};

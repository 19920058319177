import type { DependencyList } from 'react';
import { useCallback, useRef, useState } from 'react';

type State<T> =
  | { status: 'init'; error?: undefined; data?: undefined }
  | { status: 'loading'; error?: any; data?: T }
  | { status: 'error'; error: any; data?: undefined }
  | { status: 'success'; error?: undefined; data: T };

/**
 * @param fn - async function to fetch the data
 * @param deps - dependency list of `fn`. If one of the dependencies changes, the reference to the returned callback
 * function will be updated, which can be useful for triggering a refetch of the data.
 * @param initialState - initial internal state
 *
 * @returns State object and a callback function to trigger fetching the data. The promise returned by the callback
 * function will resolve with the fetched data, or resolve with an error object if `fn()` call rejects.
 */
const useAsync = <Result = any, Args extends any[] = any[]>(
  fn: (...args: Args) => Promise<Result>,
  deps: DependencyList,
  initialState: State<Result> = { status: 'init' },
) => {
  const lastCallId = useRef(0);
  const [state, set] = useState<State<Result>>(initialState);

  const callback = useCallback((...args: Args) => {
    const callId = ++lastCallId.current;
    set((state) => ({ ...state, status: 'loading' }));

    fn(...args).then(
      (data) => {
        callId === lastCallId.current && set({ data, status: 'success' });
        return data;
      },
      (error) => {
        callId === lastCallId.current && set({ error, status: 'error' });
        return error;
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return [state, callback] as const;
};

export default useAsync;

import type { VFC } from 'react';
import { Suspense, lazy, useEffect, useMemo, useState } from 'react';

import type { FallbackProps } from 'react-error-boundary';
import { ErrorBoundary } from 'react-error-boundary';
import { defineMessages } from 'react-intl';
import { css } from 'styled-components';

import { useDialogs } from '@/redux/hooks/dialogs';
import { Subtitles, Typography, cssVariables } from '@feather';
import { Button } from '@feather/components/button';
import * as Icons from '@feather/components/icons';
import { Spinner } from '@feather/components/spinner';
import useAppIntl from '@hooks/useAppIntl';
import useHideDialog from '@hooks/useHideDialog';
import { Dialog } from '@ui/components/dialog';
import { Overlay } from '@ui/components/overlay';

import { dialogComponents } from './dialogComponents';

const messages = defineMessages({
  errorTitle: { id: 'ui.dialog.error.title' },
  errorBody: { id: 'ui.dialog.error.body' },
  errorBtnRetry: { id: 'ui.dialog.error.btn.retry' },
} as const);

const ErrorFallback: VFC<FallbackProps> = ({ resetErrorBoundary }) => {
  const hideDialog = useHideDialog();
  const intl = useAppIntl();

  return (
    <Dialog
      title=""
      isFullBody={true}
      body={
        <div
          css={`
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: auto;
            margin-top: 64px;
            margin-bottom: 56px;
            text-align: center;
            color: ${cssVariables('content-3')};

            > h1 {
              margin-top: 12px;
              color: ${cssVariables('content-2')};
              ${Subtitles['subtitle-02']};
            }

            > p {
              margin-top: 4px;
              color: ${cssVariables('content-2')};
              ${Typography['caption-01']};
            }

            > button {
              margin-top: 24px;
            }
          `}
        >
          <Icons.Error size={64} color="currentColor" />
          <h1>{intl.formatMessage(messages.errorTitle)}</h1>
          <p>{intl.formatMessage(messages.errorBody)}</p>
          <Button buttonType="tertiary" icon={Icons.Refresh} size="small" onClick={resetErrorBoundary}>
            {intl.formatMessage(messages.errorBtnRetry)}
          </Button>
        </div>
      }
      onClose={hideDialog}
    />
  );
};

const DialogLoading = () => {
  const [showLoading, setShowLoading] = useState(false);
  const hideDialog = useHideDialog();
  useEffect(() => {
    const setTimeoutId = setTimeout(() => {
      setShowLoading(true);
    }, 1000);
    return () => {
      clearTimeout(setTimeoutId);
    };
  });
  return showLoading ? (
    <Dialog
      title=""
      size="small"
      isFullBody={true}
      body={<Spinner size={64} stroke={cssVariables('content-primary')} css="margin: auto;" />}
      styles={css`
        height: 240px;
      `}
      onClose={hideDialog}
      aria-busy="true"
    />
  ) : null;
};

export const Dialogs: VFC = () => {
  const hideDialog = useHideDialog();
  const { dialogTypes, dialogProps } = useDialogs((dialogs) => ({
    dialogTypes: dialogs.dialogTypes,
    dialogProps: dialogs.dialogProps,
  }));
  const [lazyComponentKey, setLazyComponentKey] = useState(0);

  const preventCloseByESC = 'preventCloseByESC' in dialogProps ? (dialogProps.preventCloseByESC as boolean) : false;
  const overlayZIndex = 'overlayZIndex' in dialogProps ? (dialogProps.overlayZIndex as number) : undefined;

  useEffect(() => {
    if (preventCloseByESC) {
      return;
    }

    const onEscKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        hideDialog();
      }
    };
    document.addEventListener('keydown', onEscKeyDown);
    return () => {
      document.removeEventListener('keydown', onEscKeyDown);
    };
  }, [hideDialog, preventCloseByESC]);

  const DialogComponent = useMemo(() => {
    const componentOrLazyOptions = dialogComponents[dialogTypes];

    if (!componentOrLazyOptions) {
      return null;
    }

    if ('importFunc' in componentOrLazyOptions) {
      const importFunc = () => {
        return componentOrLazyOptions.importFunc();
      };

      // on `lazyComponentKey` change, importFunc will be redefined to make the component reimported to recover from module loading failure.
      importFunc.key = lazyComponentKey;
      return lazy(importFunc);
    }

    return componentOrLazyOptions;
  }, [dialogTypes, lazyComponentKey]);

  const dialogComponentProps = { dialogTypes, dialogProps, onClose: hideDialog };

  if (!DialogComponent) {
    return null;
  }

  return (
    <Overlay canOutsideClickClose={false} isOpen={true} hasBackdrop={true} onClose={hideDialog} zIndex={overlayZIndex}>
      <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => setLazyComponentKey(Date.now())}>
        <Suspense fallback={<DialogLoading />}>
          <DialogComponent {...dialogComponentProps} />
        </Suspense>
      </ErrorBoundary>
    </Overlay>
  );
};

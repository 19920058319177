import type { ComponentType, VFC } from 'react';

import { DialogType } from './DialogType';

/**
 * Returns an object containing a function that can be passed to `React.lazy`.
 *
 * @param importFunc a function that calls a dynamic `import()` that must return a Promise which resolves to a module
 * with a default export containing a React component.
 */
const lazy = <T extends ComponentType<DefaultDialogProps<any>>>(importFunc: () => Promise<{ default: T }>) => ({
  importFunc,
});
const ResourceMetadataDialog = lazy(() => import('@/shared/ResourceMetadataDialog'));

const AssignTransferTicketToAgentDialog = lazy(
  () => import('@desk/containers/dialogs/AssignTransferTicketToAgentDialog'),
);
const CloseTicketDialog = lazy(() => import('@desk/containers/dialogs/CloseTicketDialog'));
const ConfirmEndOfChatDialog = lazy(() => import('@desk/containers/dialogs/ConfirmEndOfChatDialog'));
const CreateAgentGroupDialog = lazy(() => import('@desk/containers/dialogs/CreateAgentGroupDialog'));
const CreateTokenDialog = lazy(() => import('@desk/containers/dialogs/CreateTokenDialog'));
const TransferTicketToAgentGroupDialog = lazy(
  () => import('@desk/containers/dialogs/TransferTicketToAgentGroupDialog'),
);

const CreateStudioRoomDialog = lazy(() => import('@calls/containers/Studio/dialogs/CreateStudioRoomDialog'));
const CreateRoomDialog = lazy(() => import('@calls/dialogs/CreateRoomDialog'));
const DeleteRoomDialog = lazy(() => import('@calls/dialogs/DeleteRoomDialog'));
const EditCustomItemsDialog = lazy(() => import('@calls/dialogs/EditCustomItemsDialog'));
const CustomItemsColumnsDialog = lazy(() => import('@calls/dialogs/CustomItemsColumnsDialog'));
const CallsStudioRoomInviteDialog = lazy(() => import('@calls/containers/Studio/dialogs/CallsStudioRoomInviteDialog'));
const CallsStudioAddExistingRoomDialog = lazy(
  () => import('@calls/containers/Studio/dialogs/CallsStudioAddExistingRoomDialog'),
);
const CallsStudioMobileCreateUserDialog = lazy(
  () => import('@calls/containers/Studio/dialogs/CallsStudioMobileAppUserDialogs/CallsStudioMobileCreateUserDialog'),
);
const CallsStudioMobileAddUsersDialog = lazy(
  () => import('@calls/containers/Studio/dialogs/CallsStudioMobileAppUserDialogs/CallsStudioMobileAddUsersDialog'),
);
const CallsStudioMobileEditUserDialog = lazy(
  () => import('@calls/containers/Studio/dialogs/CallsStudioMobileAppUserDialogs/CallsStudioMobileEditUserDialog'),
);
const ManageDirectCallsColumnsDialog = lazy(
  () => import('@calls/containers/DirectCalls/manageDirectCallsColumnsDialog'),
);
const PushAPNSVoIPEditDialog = lazy(
  () => import('@calls/containers/Settings/Notifications/apnsDialogs/PushAPNSVoIPEditDialog'),
);
const PushAPNSVoIPRegisterDialog = lazy(
  () => import('@calls/containers/Settings/Notifications/apnsDialogs/PushAPNSVoIPRegisterDialog'),
);

const CallsStudioMobileRemoveUserDialog = lazy(
  () => import('@calls/containers/Studio/dialogs/callsStudioMobileRemoveUserDialog'),
);
const CallsUpdateSubscriptionDialog = lazy(
  () => import('../FullScreenModals/CallsVoucherModal/dialogs/CallsUpdateSubscriptionDialog'),
);
const PhoneboothUserUnregisterDialog = lazy(
  () => import('@calls/containers/Studio/dialogs/PhoneboothUserUnregisterDialog'),
);
const DirectCallsDurationLimitDialog = lazy(
  () => import('@calls/containers/DirectCalls/components/DirectCallsDurationLimitDialog'),
);
const AnalyticsExportDialog = lazy(() => import('@chat/containers/analytics/analyticsExportDialog'));
const AnnouncementDataDisplayDialog = lazy(
  () => import('@chat/containers/announcements/AnnouncementDataDisplayDialog'),
);
const AdminMessageDialog = lazy(() => import('@chat/containers/channels/dialogs/AdminMessageDialog'));
const CreateSDKUserDialog = lazy(() => import('@chat/containers/channels/dialogs/CreateSDKUserDialog'));
const DeleteChannelsDialog = lazy(() => import('@chat/containers/channels/dialogs/DeleteChannelsDialog'));
const EditChannelDialog = lazy(() => import('@chat/containers/channels/dialogs/EditChannelDialog'));
const ChannelDataDialog = lazy(() => import('@chat/containers/channels/dialogs/ChannelDataDialog'));
const UpdateSDKUserDialog = lazy(() => import('@chat/containers/channels/dialogs/UpdateSDKUserDialog'));
const CreateOpenChannelDialog = lazy(() => import('@chat/containers/channels/OpenChannels/CreateOpenChannelDialog'));
const CreateGroupChannelDialog = lazy(() => import('@chat/containers/channels/GroupChannels/CreateGroupChannelDialog'));
const ChangeToSupergroupDialog = lazy(() => import('@chat/containers/channels/GroupChannels/ChangeToSupergroupDialog'));
const DeleteMessageDialog = lazy(() => import('@chat/containers/messages/deleteMessageDialog'));
const EditMessageDialog = lazy(() => import('@chat/containers/moderationTools/dialogs/EditMessageDialog'));
const PreviousChatDialog = lazy(
  () => import('@chat/containers/moderationTools/groupChannels/PreviousChatDialog/PreviousChatDialog'),
);
const ModeratorInformationDialog = lazy(
  () => import('@chat/containers/moderationTools/dialogs/ModeratorInformationDialog'),
);
const BanMuteUserDialog = lazy(() => import('@chat/containers/moderationTools/dialogs/BanMuteUserDialog'));
const PushAPNsSaveDialog = lazy(() => import('@chat/containers/settings/notification/dialogs/PushAPNsSaveDialog'));
const PushFCMSaveDialog = lazy(() => import('@chat/containers/settings/notification/dialogs/PushFCMSaveDialog'));
const PushHuaweiSaveDialog = lazy(() => import('@chat/containers/settings/notification/dialogs/PushHuaweiSaveDialog'));
const PushTesterDialog = lazy(() => import('@chat/containers/settings/notification/dialogs/PushTesterDialog'));
const RegexEditorDialog = lazy(() => import('@chat/containers/settings/ProfanityFilterSettings/RegexEditorDialog'));
const ChangeEmailDialog = lazy(() => import('@common/containers/account/ChangeEmailDialog'));
const DeleteAccountDialog = lazy(() => import('@common/containers/account/deleteAccountDialog'));
const RegisterTwoFactorDialog = lazy(() => import('@common/containers/account/RegisterTwoFactorDialog'));
const CallsPaymentDialog = lazy(() => import('../FullScreenModals/CallsVoucherModal/dialogs/CallsPaymentDialog'));
const CallsRateTooltipDialog = lazy(() => import('@calls/dialogs/RateTooltipDialog'));
const ChangePlanDialog = lazy(
  () => import('@common/containers/FullScreenModals/SubscriptionPlanModal/ChangePlanDialog'),
);
const ChangeAppNameDialog = lazy(() => import('@common/containers/home/changeAppNameDialog'));
const DeleteApplicationDialog = lazy(() => import('@common/containers/home/deleteApplicationDialog'));
const RegisterCardDialog = lazy(() => import('@common/containers/settings/billing/RegisterCardDialog'));
const ChangeMemberRoleDialog = lazy(() => import('@common/containers/settings/members/changeMemberRoleDialog'));
const InviteMemberDialog = lazy(() => import('@common/containers/settings/members/InviteMemberDialog'));
const InviteAsGroupChannelMemberDialog = lazy(
  () => import('@chat/containers/moderationTools/components/ModerationToolHeader/InviteAsGroupChannelMemberDialog'),
);
const SelectApplicationsDialog = lazy(() => import('@common/containers/settings/roles/selectApplicationsDialog'));
const OneMoreStepToGoDialog = lazy(() => import('@common/containers/settings/security/oneMoreStepToGoDialog'));
const SamlSSOConfigDialog = lazy(() => import('@common/containers/settings/security/SamlSsoConfigDialog'));
const CallsFreeVoucherDialog = lazy(() => import('@core/containers/app/CallsFreeVoucherDialog'));
const CreateAppDialog = lazy(() => import('@core/containers/app/createAppDialog'));
const SubscriptionPlanDialog = lazy(() => import('@core/containers/overview/subscriptionPlanDialog'));
const GetAPITokenDialog = lazy(() => import('@core/containers/settings/general/GetAPITokenDialog'));
const CreateUserDialog = lazy(() => import('@core/containers/users/dialogs/createUserDialog'));
const DeactivateUserDialog = lazy(() => import('@core/containers/users/dialogs/deactivateUserDialog'));
const DeleteUsersDialog = lazy(() => import('@core/containers/users/dialogs/deleteUsersDialog'));
const IssueUserAccessTokenDialog = lazy(() => import('@core/containers/users/dialogs/issueUserAccessTokenDialog'));
const ReactivateUserDialog = lazy(() => import('@core/containers/users/dialogs/reactivateUserDialog'));
const RegisterUserAsOperatorDialog = lazy(() => import('@core/containers/users/dialogs/registerUserAsOperatorDialog'));
const AgentActivationStatusChangeDialog = lazy(
  () => import('@desk/containers/agents/list/AgentActivationStatusChangeDialog'),
);
const AgentConnectionStatusChangeDialog = lazy(
  () => import('@desk/containers/agents/list/AgentConnectionStatusChangeDialog'),
);
const SelectTwitterStatusRecipientsDialog = lazy(
  () => import('@desk/containers/conversation/selectTwitterStatusRecipientsDialog'),
);
const UpdateAgentProfileDialog = lazy(() => import('@desk/containers/conversation/UpdateAgentProfileDialog'));
const ViewAgentProfileDialog = lazy(() => import('@desk/containers/conversation/ViewAgentProfileDialog'));
const CreateProactiveChatDialog = lazy(() => import('@desk/containers/proactiveChat/CreateProactiveChatDialog'));
const DeleteAgentGroupDialog = lazy(
  () => import('@desk/containers/settings/agentGroups/DeleteAgentGroupDialog/DeleteAgentGroupDialog'),
);
const CreateDeskBotDialog = lazy(() => import('@desk/containers/settings/Bots/CreateDeskBotDialog'));
const DeactivateDeskBotDialog = lazy(() => import('@desk/containers/settings/Bots/DeactivateDeskBotDialog'));
const DeleteDeskBotDialog = lazy(() => import('@desk/containers/settings/Bots/DeleteDeskBotDialog'));
const DeleteNexmoAccountDialog = lazy(() => import('@desk/containers/settings/integration/DeleteNexmoAccountDialog'));
const AddWebhookDialog = lazy(() => import('@desk/containers/settings/webhooks/addWebhookDialog'));
const DeleteWebhookDialog = lazy(() => import('@desk/containers/settings/webhooks/deleteWebhookDialog'));
const EditWebhookDialog = lazy(() => import('@desk/containers/settings/webhooks/editWebhookDialog'));
const ReportsDataExportDialog = lazy(() => import('@desk/containers/statistics/reportsDataExportDialog'));
const ReopenTicketDialog = lazy(() => import('@desk/containers/ticketDetail/reopenTicketDialog'));
const ExportTicketDialog = lazy(() => import('@desk/containers/tickets/exportTicketDialog'));
const ExportTicketsInfoDialog = lazy(() => import('@desk/containers/tickets/exportTicketsInfoDialog'));
const ExportMissedTicketDialog = lazy(() => import('@desk/containers/views/exportMissedTicketDialog'));
const ExportPendingTicketDialog = lazy(() => import('@desk/containers/views/exportPendingTicketDialog'));
const ExportDirectCallLogsDialog = lazy(
  () => import('@calls/containers/DirectCalls/DataExports/ExportDirectCallLogsDialog'),
);
const DeleteDeskSendbirdMessageDialog = lazy(
  () => import('@desk/containers/conversation/message/DeleteDeskSendbirdMessageDialog'),
);
const EditQuickReplyCategoryDialog = lazy(() => import('@desk/containers/settings/quickReplies/EditCategoryDialog'));
const DeleteQuickReplyCategoryDialog = lazy(
  () => import('@desk/containers/settings/quickReplies/DeleteCategoryDialog'),
);
const CheckAllRequiredFieldsFilledDialog = lazy(
  () => import('@desk/containers/dialogs/CheckAllRequiredFieldsFilledDialog'),
);
const DeskAIPromotionDialog = lazy(
  () => import('@desk/containers/conversation/input/TicketInputButtons/AIPromotionDialog'),
);
const CustomerSideProfileHideTicketsDialog = lazy(
  () => import('@desk/containers/conversation/CustomerSideProfileHideTicketsDialog'),
);
const OverageDialog = lazy(() => import('../FullScreenModals/SubscriptionPlanModal/OverageDialog'));
const CallsDisableSubscriptionDialog = lazy(
  () => import('../settings/general/CallsCredits/CallsDisableSubscriptionDialog'),
);
const IPRestrictionGuideDialog = lazy(() => import('../settings/security/IPRestrictionGuideDialog'));
const ChangePasswordDialog = lazy(() => import('./ChangePassword'));
const ConfirmDialog = lazy(() => import('./ConfirmDialog'));
const ConfirmWithOrganizationNameDialog = lazy(() => import('./ConfirmWithOrganizationNameDialog'));
const Confirm1kDialog = lazy(() => import('./Confirm1kDialog'));
const ReasonForCancelDialog = lazy(() => import('./ReasonForCancelDialog'));
const ConvertFreePlanDialog = lazy(() => import('./ConvertFreePlanDialog'));
const CustomDialog = lazy(() => import('./CustomDialog'));
const DeleteDialog = lazy(() => import('./DeleteDialog'));
const UninstallDialog = lazy(() => import('./UninstallDialog'));
const SessionTimeoutDialog = lazy(() => import('./SessionTimeoutDialog'));
const AddFlaggedUserDialog = lazy(() => import('@moderation/FlaggedUsers/dialogs/AddFlaggedUserDialog'));
const DeleteFlaggedUserDialog = lazy(() => import('@moderation/FlaggedUsers/dialogs/DeleteFlaggedUserDialog'));

const DeactivateExtensionAppDialog = lazy(
  () => import('@chat/containers/settings/ExtensionsSettings/dialogs/DeactivateExtensionAppDialog'),
);
const ToggleExtensionAppStatusDialog = lazy(
  () => import('@chat/containers/settings/ExtensionsSettings/dialogs/ToggleExtensionAppStatusDialog'),
);

const CreateLiveEventDialog = lazy(() => import('@live/containers/dialogs/CreateLiveEventDialog'));
const UseDefaultProfanityKeywordsDialog = lazy(
  () => import('@chat/containers/settings/ProfanityFilterSettings/dialogs/UseDefaultProfanityKeywordDialog'),
);
const DownloadRecordingDialog = lazy(() => import('@/shared/DownloadRecordingDialog'));
const SelectPresetTemplateDialog = lazy(
  () => import('@notifications/containers/Templates/Edit/Designer/SelectPresetTemplateDialog'),
);
const CreateNotificationChannelDialog = lazy(
  () => import('@notifications/containers/Channels/CreateNotificationChannelDialog'),
);

const CreateNotificationTagDialog = lazy(
  () => import('@notifications/containers/SendNotification/CreateNotificationTagDialog'),
);

const ImportNotificationUsersDialog = lazy(
  () => import('@notifications/containers/UserInsights/ImportNotificationUsersDialog'),
);

const AiBotEmbeddingDialog = lazy(() => import('@/chat/aibots/containers/dialogs/AiBotEmbeddingDialog'));

const AiBotKnowledgeDialog = lazy(() => import('@/chat/aibots/containers/dialogs/AiBotKnowledgeDialog'));

const AiBotSubscriptionDialog = lazy(() => import('@/chat/aibots/containers/dialogs/AiBotSubscriptionDialog'));

const FormGuide = lazy(() => import('@/chat/aibots/containers/dialogs/FormGuideDialog'));

const FormPreviewDialog = lazy(() => import('@/chat/aibots/containers/Form/preview/FormPreviewDialog'));

const AppSelectorDialog = lazy(() => import('@core/containers/app/AppSelectorDialog'));

const AiBotStudioPluginDialog = lazy(() => import('@/chat/aibots/containers/dialogs/AiBotStudioPluginDialog'));

const UIKitFeatureIntroduction = lazy(() => import('@chat/containers/uikit/dialogs/UIKitFeatureIntroduction'));
const AiFeedbackDialog = lazy(() => import('@/chat/aibots/containers/Feedback/FeedbackDialog'));
const AiEmbedGuide = lazy(() => import('@chat/aibots/containers/dialogs/AiEmbedGuideDialog'));
const AddHostCandidateDialog = lazy(
  () => import('@live/containers/LiveEventDetail/LiveEventRightSidebar/AddHostCandidateDialog'),
);
const ReleaseModerationDialog = lazy(() => import('@moderation/ModeratedUsers/ReleaseModerationDialog'));

const ChannelManagerDialog = lazy(() => import('@ui/components/ChannelManagerButton/ChannelManagerDialog'));
const WhatsAppMetadataDialog = lazy(
  () => import('@notifications/containers/Templates/Edit/Designer/WhatsAppMetadataDialog'),
);
const CopyTemplateDialog = lazy(
  () => import('@notifications/containers/Templates/TemplateCopy/dialogs/CopyTemplateDialog'),
);
const CopyTemplateGuideDialog = lazy(
  () => import('@notifications/containers/Templates/TemplateCopy/dialogs/CopyTemplateGuideDialog'),
);

const ExportDataDialog = lazy(() => import('@common/containers/DataExport/ExportDataDialog'));

const PollDetailDialog = lazy(
  () => import('@/chat/containers/moderationTools/components/BaseMessage/Poll/PollDetailDialog'),
);
const TicketAssignmentDialog = lazy(() => import('@moderation/ModerationAllTicket/TicketAssignmentDialog'));
const ModeratorGroupReassignmentDialog = lazy(
  () => import('@moderation/settings/ModeratorGroup/ModeratorGroupReassignDialog'),
);
const AddCustomReportCategoryDialog = lazy(
  () => import('@moderation/settings/AdvancedModeration/ReportCategory/AddReportCategoryDialog'),
);
const ReportCategoryUsageDialog = lazy(
  () => import('@moderation/settings/AdvancedModeration/ReportCategory/ReportCategoryUsageDialog'),
);

export const dialogComponents: Record<
  DialogType,
  ReturnType<typeof lazy> | ComponentType<DefaultDialogProps<any>> | VFC<DefaultDialogProps<any>>
> = {
  [DialogType.Custom]: CustomDialog,
  [DialogType.Confirm]: ConfirmDialog,
  [DialogType.ConfirmWithOrganizationName]: ConfirmWithOrganizationNameDialog,
  [DialogType.Confirm1k]: Confirm1kDialog,
  [DialogType.ReasonForCancel]: ReasonForCancelDialog,
  [DialogType.Delete]: DeleteDialog,
  [DialogType.Uninstall]: UninstallDialog,

  [DialogType.ChangePassword]: ChangePasswordDialog,

  [DialogType.DeleteAccount]: DeleteAccountDialog,
  [DialogType.ChangeEmail]: ChangeEmailDialog,
  [DialogType.RegisterTwoFactor]: RegisterTwoFactorDialog,

  [DialogType.Overage]: OverageDialog,

  [DialogType.CreateApp]: CreateAppDialog,
  [DialogType.CallsFreeVoucher]: CallsFreeVoucherDialog,

  [DialogType.GetApiToken]: GetAPITokenDialog,
  [DialogType.SubscriptionPlan]: SubscriptionPlanDialog,

  [DialogType.DeleteApplication]: DeleteApplicationDialog,
  [DialogType.ChangeAppName]: ChangeAppNameDialog,
  [DialogType.InviteMember]: InviteMemberDialog,

  [DialogType.InviteAsGroupChannelMember]: InviteAsGroupChannelMemberDialog,

  [DialogType.ChangeMemberRole]: ChangeMemberRoleDialog,

  [DialogType.RegisterCard]: RegisterCardDialog,

  [DialogType.SamlOneMoreStepToGo]: OneMoreStepToGoDialog,

  [DialogType.SelectApplicationsToAccess]: SelectApplicationsDialog,

  [DialogType.CreateOpenChannel]: CreateOpenChannelDialog,
  [DialogType.CreateGroupChannel]: CreateGroupChannelDialog,
  [DialogType.ChangeToSupergroup]: ChangeToSupergroupDialog,
  [DialogType.ChannelData]: ChannelDataDialog,
  [DialogType.ResourceMetadata]: ResourceMetadataDialog,
  [DialogType.EditChannel]: EditChannelDialog,
  [DialogType.DeleteChannels]: DeleteChannelsDialog,
  [DialogType.AdminMessage]: AdminMessageDialog,
  [DialogType.CreateSDKUser]: CreateSDKUserDialog,
  [DialogType.UpdateSDKUser]: UpdateSDKUserDialog,

  [DialogType.EditMessage]: EditMessageDialog,
  [DialogType.DeleteMessage]: DeleteMessageDialog,
  [DialogType.ModeratorInformation]: ModeratorInformationDialog,

  [DialogType.BanMuteUser]: BanMuteUserDialog,

  [DialogType.CreateUser]: CreateUserDialog,
  [DialogType.DeleteUser]: DeleteUsersDialog,
  [DialogType.DeactivateUser]: DeactivateUserDialog,
  [DialogType.ReactivateUser]: ReactivateUserDialog,

  [DialogType.RegisterUserAsOperator]: RegisterUserAsOperatorDialog,

  [DialogType.IssueUserAccessToken]: IssueUserAccessTokenDialog,

  [DialogType.AnalyticsExport]: AnalyticsExportDialog,
  [DialogType.AnnouncementDataDisplay]: AnnouncementDataDisplayDialog,

  [DialogType.PushApnsSave]: PushAPNsSaveDialog,
  [DialogType.PushApnsVoipRegister]: PushAPNSVoIPRegisterDialog,
  [DialogType.PushApnsVoipEdit]: PushAPNSVoIPEditDialog,
  [DialogType.PushFcmSave]: PushFCMSaveDialog,

  [DialogType.PushHuaweiSave]: PushHuaweiSaveDialog,
  [DialogType.PushTester]: PushTesterDialog,

  [DialogType.UpdateAgentProfile]: UpdateAgentProfileDialog,
  [DialogType.ViewAgentProfile]: ViewAgentProfileDialog,
  [DialogType.SelectTwitterStatusRecipients]: SelectTwitterStatusRecipientsDialog,

  [DialogType.ConfirmEndOfChat]: ConfirmEndOfChatDialog,
  [DialogType.AssignTransferTicketToAgent]: AssignTransferTicketToAgentDialog,
  [DialogType.AssignTransferTicketToGroup]: TransferTicketToAgentGroupDialog,
  [DialogType.CreateAgentGroup]: CreateAgentGroupDialog,
  [DialogType.CloseTicket]: CloseTicketDialog,
  [DialogType.ReopenTicket]: ReopenTicketDialog,
  [DialogType.ExportTicket]: ExportTicketDialog,
  [DialogType.ExportTicketsInfo]: ExportTicketsInfoDialog,

  [DialogType.CreateNewToken]: CreateTokenDialog,

  [DialogType.AddWebhook]: AddWebhookDialog,
  [DialogType.EditWebhook]: EditWebhookDialog,
  [DialogType.DeleteWebhook]: DeleteWebhookDialog,

  [DialogType.ReportsExport]: ReportsDataExportDialog,

  [DialogType.CreateProactiveChat]: CreateProactiveChatDialog,
  [DialogType.DeleteAgentGroup]: DeleteAgentGroupDialog,
  [DialogType.ManageDirectCallsColumns]: ManageDirectCallsColumnsDialog,
  [DialogType.ExportMissedTicket]: ExportMissedTicketDialog,
  [DialogType.ExportPendingTicket]: ExportPendingTicketDialog,
  [DialogType.ExportDirectCallLogs]: ExportDirectCallLogsDialog,
  [DialogType.PhoneboothUserUnregister]: PhoneboothUserUnregisterDialog,
  [DialogType.DirectCallsDurationLimit]: DirectCallsDurationLimitDialog,
  [DialogType.CreateRoom]: CreateRoomDialog,
  [DialogType.CreateStudioRoom]: CreateStudioRoomDialog,
  [DialogType.DeleteRoom]: DeleteRoomDialog,
  [DialogType.CallsEditCustomItems]: EditCustomItemsDialog,
  [DialogType.CallsCustomItemsColumns]: CustomItemsColumnsDialog,
  [DialogType.CallsStudioRoomInvite]: CallsStudioRoomInviteDialog,
  [DialogType.CallsStudioAddExistingRoom]: CallsStudioAddExistingRoomDialog,
  [DialogType.CallsStudioMobileCreateUser]: CallsStudioMobileCreateUserDialog,
  [DialogType.CallsStudioMobileAddUser]: CallsStudioMobileAddUsersDialog,
  [DialogType.CallsStudioMobileEditUser]: CallsStudioMobileEditUserDialog,
  [DialogType.CallsStudioMobileRemoveUser]: CallsStudioMobileRemoveUserDialog,
  [DialogType.CallsUpdateSubscription]: CallsUpdateSubscriptionDialog,
  [DialogType.CallsDisableSubscription]: CallsDisableSubscriptionDialog,
  [DialogType.CallsPayment]: CallsPaymentDialog,
  [DialogType.CallsRateTooltip]: CallsRateTooltipDialog,
  [DialogType.ChangePlan]: ChangePlanDialog,
  [DialogType.ConvertFreePlan]: ConvertFreePlanDialog,
  [DialogType.AgentConnectionStatusChange]: AgentConnectionStatusChangeDialog,
  [DialogType.AgentActivationStatusChange]: AgentActivationStatusChangeDialog,
  [DialogType.RegexEditor]: RegexEditorDialog,
  [DialogType.DeleteNexmoAccount]: DeleteNexmoAccountDialog,
  [DialogType.IPRestrictionGuide]: IPRestrictionGuideDialog,
  [DialogType.CreateDeskBot]: CreateDeskBotDialog,
  [DialogType.DeactivateDeskBot]: DeactivateDeskBotDialog,
  [DialogType.DeleteDeskBot]: DeleteDeskBotDialog,
  [DialogType.DeskAIPromotion]: DeskAIPromotionDialog,
  [DialogType.CustomerHideTickets]: CustomerSideProfileHideTicketsDialog,
  [DialogType.PreviousChat]: PreviousChatDialog,
  [DialogType.SSOConfig]: SamlSSOConfigDialog,
  [DialogType.DeleteDeskSendbirdMessage]: DeleteDeskSendbirdMessageDialog,
  [DialogType.SessionTimeout]: SessionTimeoutDialog,
  [DialogType.EditQuickReplyCategory]: EditQuickReplyCategoryDialog,
  [DialogType.DeleteQuickReplyCategory]: DeleteQuickReplyCategoryDialog,
  [DialogType.CheckAllRequiredFieldsFilled]: CheckAllRequiredFieldsFilledDialog,
  [DialogType.DeactivateExtensionApp]: DeactivateExtensionAppDialog,
  [DialogType.ToggleExtensionAppStatus]: ToggleExtensionAppStatusDialog,
  [DialogType.AddFlaggedUsers]: AddFlaggedUserDialog,
  [DialogType.DeleteFlaggedUsers]: DeleteFlaggedUserDialog,
  [DialogType.CreateLiveEvent]: CreateLiveEventDialog,
  [DialogType.UseDefaultProfanityKeywords]: UseDefaultProfanityKeywordsDialog,
  [DialogType.DownloadRecording]: DownloadRecordingDialog,
  [DialogType.SelectPresetTemplate]: SelectPresetTemplateDialog,
  [DialogType.CreateNotificationChannel]: CreateNotificationChannelDialog,
  [DialogType.CreateNotificationTag]: CreateNotificationTagDialog,
  [DialogType.ImportNotificationUsers]: ImportNotificationUsersDialog,
  [DialogType.AiBotEmbedding]: AiBotEmbeddingDialog,
  [DialogType.AiBotKnowledge]: AiBotKnowledgeDialog,
  [DialogType.AiBotSubscription]: AiBotSubscriptionDialog,
  [DialogType.AppSelector]: AppSelectorDialog,
  [DialogType.AiBotStudioPlugin]: AiBotStudioPluginDialog,
  [DialogType.AiFeedback]: AiFeedbackDialog,
  [DialogType.AiEmbedGuide]: AiEmbedGuide,
  [DialogType.UIKitFeatureIntroduction]: UIKitFeatureIntroduction,
  [DialogType.AddHostCandidate]: AddHostCandidateDialog,
  [DialogType.ReleaseModeration]: ReleaseModerationDialog,
  [DialogType.ChannelManager]: ChannelManagerDialog,
  [DialogType.ExportData]: ExportDataDialog,
  [DialogType.FormGuide]: FormGuide,
  [DialogType.FormPreview]: FormPreviewDialog,
  [DialogType.WhatsAppMetadata]: WhatsAppMetadataDialog,
  [DialogType.CopyTemplate]: CopyTemplateDialog,
  [DialogType.CopyTemplateGuide]: CopyTemplateGuideDialog,
  [DialogType.PollDetail]: PollDetailDialog,
  [DialogType.TicketAssignment]: TicketAssignmentDialog,
  [DialogType.ModeratorGroupReassignment]: ModeratorGroupReassignmentDialog,
  [DialogType.AddCustomReportCategory]: AddCustomReportCategoryDialog,
  [DialogType.ReportCategoryUsage]: ReportCategoryUsageDialog,
};

import { useContext, useEffect, useMemo } from 'react';

import isEmpty from 'lodash/isEmpty';

import { SupportPlanContext } from '@/SupportPlanContext';
import { useCurrentChatSubscription } from '@common/containers/CurrentChatSubscriptionProvider';

import useAuthRole from '../useAuthRole';
import useOrganization from '../useOrganization';
import useUser from '../useUser';

// https://sendbird.atlassian.net/browse/AC-3037
export const gainsightEventNames = {
  whatsNew: 'gnb_button_whats_new',

  goToBotSettings: 'onboarding_bot_button_go_to_bot_settings',
  coachMarkGotIt: 'onboarding_bot_coachmark_got_it',
  addToMyShopify: 'shopify_bot_button_add_to_my_shopify',
  formsCreateButtonCreate: 'forms_create_button_create',
  functionCallCreated: 'functioncall_created',
  workflowCreated: 'workflow_created',
  onboardingSuggestedReplyClicked: 'preview_suggested_reply_clicked',
  onboardingUrlInput: 'onboarding_url_input',
  onboardingSkipToBotStudio: 'onboarding_skip_to_bot_studio',

  botButtonAddToMyWebsite: 'bot_button_add_to_my_website',
  botButtonApplyChanges: 'bot_button_apply_changes',
  botButtonAITranslation: 'bot_button_ai_translation',
  botButtonPromptInstructions: 'bot_button_prompt_instructions',
  botButtonAddSrc: 'bot_buton_add_source',
  botButtonCrawlAllPages: 'bot_button_crawl_all_pages',

  botDivAddKnowledgeSourceUrl: 'bot_div_add_knowledge_source_url',
  botDivAddKnowledgeSourceFile: 'bot_div_add_knowledge_source_file',
  botDivAddKnowledgeSourceQna: 'bot_div_add_knowledge_source_qna',
  botDivAddKnowledgeSourceNotion: 'bot_div_add_knowledge_source_notion',
  botDivAddKnowledgeSourceGoogleDrive: 'bot_div_add_knowledge_source_google_drive',
  botDivAddKnowledgeSourceSalesforce: 'bot_div_add_knowledge_source_salesforce',
} as const;

export const sendGainsightEvent = ({
  eventName,
  properties,
}: {
  eventName: string;
  properties?: Record<string, unknown>;
}) => {
  if (window.aptrinsic == null) {
    return;
  }
  window.aptrinsic('track', eventName, properties);
};

const useGainsight = () => {
  const { is_self_serve: isOrgV2 } = useOrganization();
  const { email, first_name, last_name, country_name, join_date } = useUser();
  const role = useAuthRole();
  const organization = useOrganization();
  const safeOrganization = isEmpty(organization) ? null : organization;
  const { uid, name, created_at } = {
    uid: safeOrganization?.uid ?? '',
    name: safeOrganization?.name ?? '',
    created_at: safeOrganization?.created_at ?? '',
  };
  const { isLoaded: isSupportPlanLoaded, current: supportData } = useContext(SupportPlanContext); // supportData can be null
  const { isLoaded: isOrgV2SubscriptionLoaded, currentSubscription: orgV2AccountInfo } = useCurrentChatSubscription();

  const skipGain = useMemo(
    () => email === '' || !isSupportPlanLoaded || (isOrgV2 ? !isOrgV2SubscriptionLoaded : false),
    [email, isSupportPlanLoaded, isOrgV2, isOrgV2SubscriptionLoaded],
  );

  useEffect(() => {
    if (window.aptrinsic == null || skipGain) {
      return;
    }
    //passing user and account objects:
    window.aptrinsic(
      'identify',
      {
        // User Fields
        id: email, // Required for logged in app users
        email,
        firstName: first_name,
        lastName: last_name,
        signUpDate: new Date(join_date).valueOf(), //unix time in ms
        // Custom fields
        role: role.name,
        countryName: country_name,
        language: navigator ? navigator.language : 'unknown',
      },
      {
        // Account Fields
        id: uid, // Required
        name,
        createdAt: new Date(created_at).valueOf(),
        customerType: orgV2AccountInfo?.subscription_type ?? '',
        subscriptionName: orgV2AccountInfo?.subscription_name ?? '',
        subscriptionValue: orgV2AccountInfo?.plan_value ?? '',
        supportType: supportData?.display_name ?? '',
        supportValue: supportData?.plan_value ?? 0,
      },
    );
  }, [
    email,
    first_name,
    last_name,
    country_name,
    role,
    uid,
    name,
    created_at,
    orgV2AccountInfo,
    supportData,
    skipGain,
    join_date,
  ]);

  return null;
};

// Initialized as a React Component because it needs to be under SupportPlanContextProvider.
const GainsightInitializer = () => {
  useGainsight();
  return null;
};

export default GainsightInitializer;

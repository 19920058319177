import { useCallback, useEffect, useRef } from 'react';

import { useDispatch } from '@/redux/hooks';
import { DialogsActions } from '@common/redux/actions/dialogs';

function useShowDialog(options: ShowDialogPayload): () => void;
function useShowDialog(): (payload: ShowDialogPayload) => void;
function useShowDialog(options?): any {
  const dispatch = useDispatch();
  const optionsRef = useRef(options);

  // save options in ref to keep the reference of returned function.
  useEffect(() => {
    optionsRef.current = options;
  });

  return useCallback(
    (payload?: ShowDialogPayload) => {
      if (optionsRef.current) {
        dispatch(DialogsActions.showDialog(optionsRef.current));
        return;
      }
      if (payload) {
        dispatch(DialogsActions.showDialog(payload));
      }
    },
    [dispatch],
  );
}

export default useShowDialog;
